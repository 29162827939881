import React from "react";
import { Box, Button, rem, Text } from "@mantine/core";
import { IconCircleDotFilled } from "@tabler/icons-react";
import ACD_EXPERT from "../../../assets/images/acdexperts.png";
import { colors } from "../../../configs";
import expertStyles from "./expertStyles";

const AcdExpert = () => {
  const { classes } = expertStyles();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.leftBox}>
        <Text className={classes.leftBoxTitle}>
          Besoin d'être accompagné(e) ?
        </Text>
        <Text className={classes.leftBoxSubTitle}>
          Nos experts et juristes sont à votre écoute !
        </Text>
        <Text className={classes.leftBoxText}>
          Les experts Legafrik restent entièrement disponibles pour répondre à
          vos questions et préoccupations !
        </Text>
        <Button
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href="/formulaire-demande-acd"
          leftIcon={<IconCircleDotFilled size={rem(18)} />}
          styles={(theme) => ({
            root: {
              margin: "0 auto",
              //  alignSelf: "center",
              backgroundColor: colors.primary,
              border: 0,
              height: rem(52),

              /*
              paddingLeft: rem(20),
              paddingRight: rem(20),
              */
              //  width: "400px",
              borderRadius: "45px",
              "&:not([data-disabled])": theme.fn.hover({
                backgroundColor: theme.fn.darken("#00acee", 0.05),
              }),
            },

            leftIcon: {
              marginRight: theme.spacing.md,
            },
          })}>
          Contactez nos experts
        </Button>
      </Box>
      <Box className={classes.rightBox}>
        <img src={ACD_EXPERT} alt="Contactez nos expetts" width="70%" />
      </Box>
    </Box>
  );
};

export default AcdExpert;
