import { Box } from "@mantine/core";
import React from "react";
import { IconUsersGroup, IconNotes } from "@tabler/icons-react";
import IBCard from "./IBCard";

const IntroBanner = () => {
  return (
    <Box
      sx={{ backgroundColor: "#d4dedd", padding: 2, marginTop: "50px" }}
      styles={(theme) => ({})}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <IBCard
          text1={"Plus de 7000 entreprises"}
          text2={"clientes"}
          bgColor={"#425655"}
          icon={<IconUsersGroup color="#FFFFFF" />}
        />
        <IBCard
          text1={"Plus de 20.000 formalités "}
          text2={"accomplies"}
          bgColor={"#009933"}
          icon={<IconNotes color="#FFFFFF" />}
        />
      </Box>
    </Box>
  );
};

export default IntroBanner;
