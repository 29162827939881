const docsList = [
  {
    label: "Attestation d'identité",
    value: "ATT_IDE",
    icon: "",
  },
  {
    label: "Passport",
    value: "PASS",
    icon: "",
  },
  {
    label: "Permis de conduire",
    value: "PER_CON",
    icon: "",
  },
  {
    label: "Carte d'identité nationale",
    value: "CAR_IDE_NAT",
    icon: "",
  },
  {
    label: "Acte de naissance",
    value: "ACT_NAI",
    icon: "",
  },
  {
    label: "Attestation villageoise",
    value: "ATT_VIL",
    icon: "",
  },
  {
    label: "Lettre d'attribution",
    value: "LET_ATT",
    icon: "",
  },
  {
    label: "Certificat de propriété",
    value: "CER_PRO",
    icon: "",
  },
  {
    label: "Certificat de mutation de propriété",
    value: "CER_MUT_PRO",
    icon: "",
  },
];

export default docsList;
