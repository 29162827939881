import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase-config";
import { Badge, Box, Tabs, Text } from "@mantine/core";
import {
  IconBuildingCommunity,
  IconHomeDollar,
  IconRoad,
  IconSearch,
} from "@tabler/icons-react";
import PreAchatCard from "./PreAchatCard/PreAchatCard";
import AcdCard from "./AcdCard/AcdCard";
import VenteCard from "./VenteCard/VenteCard";
import GestionCard from "./GestionCard/GestionCard";
import commandesStyles from "./commandesStyles";
import { colors } from "../../../configs";

const Commandes = () => {
  const { classes } = commandesStyles();
  const location = useLocation();
  const { userId } = location.state;
  const [reqs, setReqs] = useState<any[] | null>(null);
  const [gestionReqs, setGestionsReqs] = useState<any[]>([]);
  const [venteReqs, setVenteReqs] = useState<any[]>([]);
  const [acdReqs, setAcdReqs] = useState<any[]>([]);
  const [preachatReqs, setPreachatReqs] = useState<any[]>([]);

  const getOrdersList = async () => {
    const tempReqs: any[] = [];
    const pres: any[] = [];
    const acds: any[] = [];
    const vens: any[] = [];
    const gess: any[] = [];

    const q = query(
      collection(db, "requests"),
      where("requesterId", "==", userId)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      const req = doc.data();
      req.id = doc.id;
      tempReqs.push(req);
      if (req.model == "preachat") {
        pres.push(req);
      } else if (req.model == "gestion") {
        gess.push(req);
      } else if (req.model == "vente") {
        vens.push(req);
      } else if (req.model == "acd") {
        acds.push(req);
      }
    });
    setPreachatReqs(pres);
    setGestionsReqs(gess);
    setVenteReqs(vens);
    setAcdReqs(acds);
    setReqs(tempReqs);
  };

  useEffect(() => {
    getOrdersList();
  }, [userId]);
  return (
    <>
      <Box className={classes.wrapper}>
        <Text
          sx={{
            color: colors.primary,
            fontWeight: "bolder",
            fontSize: "21px",
          }}>
          Tableau de bord de vos demandes
        </Text>
        <Tabs variant="pills" defaultValue="check">
          <Tabs.List grow>
            <Tabs.Tab
              icon={<IconSearch size="2.5rem" />}
              rightSection={
                <Badge
                  w={16}
                  h={16}
                  sx={{ pointerEvents: "none" }}
                  size="xs"
                  p={0}>
                  {preachatReqs.length}
                </Badge>
              }
              value="check">
              Préacquisition
            </Tabs.Tab>
            <Tabs.Tab
              icon={<IconRoad size="2.5rem" />}
              value="acd"
              rightSection={
                <Badge
                  w={16}
                  h={16}
                  sx={{ pointerEvents: "none" }}
                  size="xs"
                  p={0}>
                  {acdReqs.length}
                </Badge>
              }>
              Demandes ACD
            </Tabs.Tab>
            <Tabs.Tab
              icon={<IconHomeDollar size="2.5rem" />}
              value="vente"
              rightSection={
                <Badge
                  w={16}
                  h={16}
                  sx={{ pointerEvents: "none" }}
                  size="xs"
                  p={0}>
                  {venteReqs.length}
                </Badge>
              }>
              Ventes
            </Tabs.Tab>
            <Tabs.Tab
              icon={<IconBuildingCommunity size="2.5rem" />}
              value="gestion"
              rightSection={
                <Badge
                  w={16}
                  h={16}
                  sx={{ pointerEvents: "none" }}
                  size="xs"
                  p={0}>
                  {gestionReqs.length}
                </Badge>
              }>
              Gestion Locative
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="check">
            <Box className={classes.tabsBox}>
              {preachatReqs.map((pReq) => (
                <PreAchatCard req={pReq} />
              ))}
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value="acd">
            <Box className={classes.tabsBox}>
              {acdReqs.map(() => (
                <AcdCard />
              ))}
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value="vente">
            <Box className={classes.tabsBox}>
              {venteReqs.map(() => (
                <VenteCard />
              ))}
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value="gestion">
            <Box className={classes.tabsBox}>
              {gestionReqs.map(() => (
                <GestionCard />
              ))}
            </Box>
          </Tabs.Panel>
        </Tabs>
      </Box>
    </>
  );
};

export default Commandes;
