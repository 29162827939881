import { createStyles } from "@mantine/core";
const ibcardStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    margin: "5px 30px",

    /*
    [theme.fn.largerThan("xl")]: { padding: "6px 8%", height: "80vh" },
    [theme.fn.smallerThan("xl")]: { padding: "6px 8%", height: "75vh" },
    [theme.fn.smallerThan("lg")]: { padding: "6px 8%", height: "70vh" },
    [theme.fn.smallerThan("md")]: { padding: "3px 7px", height: "65vh" },
    [theme.fn.smallerThan("sm")]: { padding: "3px 7px", height: "60vh" },
    [theme.fn.smallerThan("xs")]: {
      padding: "2px 5px",
      height: "60vh",
    },
*/
  },

  ibcIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    [theme.fn.largerThan("xl")]: { height: "50px", width: "50px" },
    [theme.fn.smallerThan("xl")]: { height: "50px", width: "50px" },
    [theme.fn.smallerThan("lg")]: { height: "50px", width: "50px" },
    [theme.fn.smallerThan("md")]: { height: "40px", width: "40px" },
    [theme.fn.smallerThan("sm")]: { height: "35px", width: "35px" },
    [theme.fn.smallerThan("xs")]: { height: "30px", width: "30px" },
  },
  /*
  fbbr: {
    display: "flex",
    [theme.fn.smallerThan("sm")]: { display: "flex" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
*/
  ibcText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#425655",

    [theme.fn.largerThan("xl")]: { fontSize: "20px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "20px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "20px" },
    [theme.fn.smallerThan("md")]: { fontSize: "20px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "18px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "10px", textAlign: "center" },
  },
}));

export default ibcardStyles;
