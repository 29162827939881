import { createStyles } from "@mantine/core";
import { colors, sizes } from "../../../configs";
const heroStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#c6d2d2",
    [theme.fn.largerThan("xl")]: { padding: sizes.supXl_section_pd },
    [theme.fn.smallerThan("xl")]: { padding: sizes.xl_section_pd },
    [theme.fn.smallerThan("lg")]: { padding: sizes.lg_section_pd },
    [theme.fn.smallerThan("md")]: { padding: sizes.md_section_pd },
    [theme.fn.smallerThan("sm")]: {
      padding: sizes.sm_section_pd,
      flexDirection: "column",
    },
    [theme.fn.smallerThan("xs")]: {
      padding: sizes.xs_section_pd,
      flexDirection: "column",
      height: "100%",
    },
    height: "80vh",
    gap: 15,
  },
  leftBox: {
    flex: 2.25,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  centerBox: {
    flex: 1.25,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: colors.secondary,
    padding: "20px",
    [theme.fn.largerThan("xl")]: {},
    [theme.fn.smallerThan("xl")]: {},
    [theme.fn.smallerThan("lg")]: {},
    [theme.fn.smallerThan("md")]: { flexDirection: "row" },
    [theme.fn.smallerThan("sm")]: {
      backgroundColor: "transparent",
      flexDirection: "row",
    },
    [theme.fn.smallerThan("xs")]: {
      backgroundColor: "transparent",
      flexDirection: "row",
    },
    [theme.fn.smallerThan("xxs")]: {
      backgroundColor: "transparent",
      flexDirection: "column",
    },
  },
  rightBox: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.fn.smallerThan("md")]: { display: "none" },
    [theme.fn.smallerThan("sm")]: { display: "none" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },

  title: {
    fontWeight: 900,
    lineHeight: 1.4,
    marginBottom: theme.spacing.md,
    color: colors.primary,
    [theme.fn.largerThan("xl")]: { fontSize: sizes.supXl_title },
    [theme.fn.smallerThan("xl")]: { fontSize: sizes.xl_title },
    [theme.fn.smallerThan("lg")]: { fontSize: sizes.lg_title },
    [theme.fn.smallerThan("md")]: { fontSize: sizes.md_title },
    [theme.fn.smallerThan("sm")]: { fontSize: sizes.sm_title },
    [theme.fn.smallerThan("xs")]: { fontSize: sizes.xs_title },
  },

  description: {
    color: colors.primary,
    [theme.fn.largerThan("xl")]: { fontSize: sizes.supXl_desc },
    [theme.fn.smallerThan("xl")]: { fontSize: sizes.xl_desc },
    [theme.fn.smallerThan("lg")]: { fontSize: sizes.lg_desc },
    [theme.fn.smallerThan("md")]: { fontSize: sizes.md_desc },
    [theme.fn.smallerThan("sm")]: { fontSize: sizes.sm_desc },
    [theme.fn.smallerThan("xs")]: { fontSize: sizes.xs_desc },
  },
  iconsBox: {
    display: "flex",
    gap: 10,
    [theme.fn.smallerThan("sm")]: { display: "none" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
}));

export default heroStyles;
