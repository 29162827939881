import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Acd from "./pages/Acd/Acd";
import Vente from "./pages/Vente/Vente";
import Gestion from "./pages/Gestion/Gestion";
import Verification from "./pages/Verification/Verification";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Contact from "./pages/Contact/Contact";
import UiTest from "./pages/UiTest/UiTest";
import Profile from "./pages/Profile/Profile";
import Compte from "./pages/Profile/Compte/Compte";
import Notifs from "./pages/Profile/Notifs/Notifs";
import Reglages from "./pages/Profile/Reglages/Reglages";
import Commandes from "./pages/Profile/Commandes/Commandes";
import Password from "./pages/Profile/Password/Password";
import EditProfile from "./pages/Profile/EditProfile/EditProfile";
import FormForSell from "./forms/FormForSell";
import FormForGestion from "./forms/FormForgestion";
import FormForChecking from "./forms/FormForChecking";
import FormForAcd from "./forms/FormForAcd";
import ScrollToHashElement from "./components/ScrollToHashElement/ScrollToHashElement";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="demarches-acd" element={<Acd />} />
        <Route path="/formulaire-demande-acd" element={<FormForAcd />} />
        <Route path="vente" element={<Vente />} />
        <Route path="/formulaire-vente" element={<FormForSell />} />
        <Route path="gestion-locative" element={<Gestion />} />
        <Route
          path="/formulaire-gestion-locative"
          element={<FormForGestion />}
        />
        <Route path="verification-preacquisition" element={<Verification />} />
        <Route
          path="/formulaire-preacquisition"
          element={<FormForChecking />}
        />
        <Route path="/inscription" element={<Register />} />
        <Route path="/connexion" element={<Login />} />
        <Route path="profile" element={<Profile />}>
          <Route index path="" element={<Compte />} />
          <Route path="notifs" element={<Notifs />} />
          <Route path="reglages" element={<Reglages />} />
          <Route path="commandes" element={<Commandes />} />
          <Route path="modifier-profile" element={<EditProfile />} />
          <Route path="mot-de-passe" element={<Password />} />
        </Route>
        <Route path="/contact" element={<Contact />} />
        <Route path="/learning" element={<UiTest />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
