const colors = {
  primary: "#425655",
  //secondary: "#F7D364",
  secondary: "#f2b90d",

  primaryLight: "#5b8785",
  secondaryLight: "#f9e6ae",
};

export default colors;
