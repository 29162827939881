import React from "react";

const Testimonial = () => {
  return <></>;
  /*  
        <div
      className="fb-video"
      data-href="https://web.facebook.com/100088106414929/videos/1640113236409277"
      data-width="500"
      data-show-text="true">
      <blockquote
        cite="https://www.facebook.com/100088106414929/videos/1640113236409277/"
        className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/100088106414929/videos/1640113236409277/"></a>
        <p>
          🚨🚨🚨QUE SE PASSE T-IL LORSQU&#039;UNE PERSONNE CONSTRUIT SUR VOTRE
          TERRAIN? Cette petite vidéo avec les grands axes pour vous éclairer.
          On se retrouve bientôt pour une vidéo qui traitera le sujet en
          profondeur. #gbairaiFoncierDuDimanche #TransparenceImmobiliere On
          vérifie pour vous, les informations sur le vendeur et le terrain que
          vous voulez acheter.
        </p>
        Publiée par{" "}
        <a href="https://www.facebook.com/people/Transparence-immobili%C3%A8re/100088106414929/">
          Transparence immobilière{" "}
        </a>{" "}
        sur Dimanche 12 mars 2023
      </blockquote>
    </div>
    */
};

export default Testimonial;
