import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import Cookies from "js-cookie";
import { createUserWithEmailAndPassword } from "firebase/auth";
import useFirebaseAuth from "../context/authuser/useFirebaseAuth";
import {
  Autocomplete,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Select,
  SelectItemProps,
  Text,
  TextInput,
} from "@mantine/core";
import emailStyles from "./emailStyles";
import { useForm } from "@mantine/form";
import countries from "../shared/countries";
// components
// import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const EmailRegisterForm = () => {
  const form = useForm({
    initialValues: {
      name: "",
      firstname: "",
      email: "",
      country: "",
      repeatEmail: "",
      password: "",
    },
    validate: {
      name: (value) => value.trim().length < 2,
      firstname: (value) => value.trim().length < 2,
      email: (value) => !/^\S+@\S+$/.test(value),
      repeatEmail: (value) => !/^\S+@\S+$/.test(value),
      password: (value) => value.trim().length === 0,
      country: (value) => value.trim().length === 0,
    },
  });
  const { classes } = emailStyles();
  const navigate = useNavigate();

  const { loggedUser } = useFirebaseAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");

  const register = (data: any) => {
    setErrorMsg("");
    setSuccessMsg("");
    setLoading(true);
    console.log("====================================");
    console.log("form data: ", data);
    console.log("====================================");

    createUserWithEmailAndPassword(getAuth(), data.email, data.password)
      .then(async (user) => {
        console.log("====================================");
        console.log("USER REGISTERED SUCCESS: ", user);
        console.log("====================================");
        try {
          await setDoc(doc(db, "users", user.user.uid), {
            uid: user.user.uid,
            email: data.email,
            origin: "Transparence Immobilière",
            name: data.name,
            firstname: data.firstname,
            password: data.password,
            country: data.country,
            createdAt: new Date().getTime(),
            lastLogin: new Date().getTime(),
          });

          Cookies.set("auth_token", user.user.uid);
          setLoading(false);
          setSuccessMsg("Inscription réussie, redirection en cours...");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } catch (e) {
          setLoading(false);
          setErrorMsg("Erreur, actualisez la page puis recommencez.");
          console.error("Error adding document: ", e);
        }
      })
      .catch((regError) => {
        setLoading(false);

        console.warn("USER REGISTERED ERROR: ", regError);
        switch (regError.code) {
          case "auth/email-already-in-use":
            setErrorMsg(
              "E-mail déja utilisée, avez vous oublié votre mot de passe ?"
            );
            //  this.showResetLink = true;
            break;
          case "auth/invalid-email":
            setErrorMsg("E-mail invalide");
            break;
          case "auth/weak-password":
            setErrorMsg(
              "Mot de passe trop faible, minimum 6 caractères requis."
            );
            break;
          default:
            setErrorMsg(
              "Erreur inconue, réessayez ou contactez l'administrateur"
            );
            break;
        }
      });
  };

  return (
    <>
      <form onSubmit={form.onSubmit((data) => register(data))}>
        <Box
          m={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 5,
          }}>
          <TextInput
            sx={{ flex: 2 }}
            className={classes.inputs}
            name="name"
            label="Nom"
            //  value={name ? name : ""}
            {...form.getInputProps("name")}
            //  onChange={(nameEvt: any) => setName(nameEvt.target.value)}
          />
          <TextInput
            sx={{ flex: 3.5 }}
            className={classes.inputs}
            name="firstname"
            label="Prénoms(s)"
            //  value={firstname ? firstname : ""}
            {...form.getInputProps("firstname")}
            //  onChange={(ftNameEvt: any) => setFirstname(ftNameEvt.target.value)}
          />
        </Box>
        <Box m={1}>
          <Select
            className={classes.inputs}
            searchable
            label="Pays de résidence"
            placeholder="Cliquez-ici"
            nothingFound="Aucune correspondance"
            data={countries}
            clearable
            {...form.getInputProps("country")}
          />
          <TextInput
            className={classes.inputs}
            name="email"
            type="email"
            label="Votre adresse mail"
            //  value={email}
            {...form.getInputProps("email")}
            //  onChange={(emailEvt: any) => setEmail(emailEvt.target.value)}
          />
          <TextInput
            className={classes.inputs}
            name="emailConfirm"
            type="email"
            label="Repetez adresse mail"
            //  value={repeatEmail}
            {...form.getInputProps("repeatEmail")}
            //  onChange={(emailEvt: any) => setRepeatEmail(emailEvt.target.value)}
          />
          <TextInput
            className={classes.inputs}
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            //  value={password}
            {...form.getInputProps("password")}
            /*
            onChange={(passwordEvt: any) =>
              setPassword(passwordEvt.target.value)
            }
            */
          />
        </Box>
        <Text variant="body2" color="success">
          {successMsg}
        </Text>
        <Text variant="body2" color="error">
          {errorMsg}
        </Text>

        <Button className={classes.confirmButon} type="submit">
          Confirmer
        </Button>
      </form>
      {loading && (
        <>
          <LoadingOverlay
            style={{
              color: "#000000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              margin: "0 auto",
            }}
            visible={loading}
            overlayBlur={2}
            onClick={() => null}
          />
        </>
      )}
    </>
  );
};
export default EmailRegisterForm;
