import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MantineProvider } from "@mantine/core";

import reportWebVitals from "./reportWebVitals";
import { AuthUserProvider } from "./context/authuser/auth.context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        fontFamily: "Greycliff CF, sans-serif",
        breakpoints: {
          xxs: "21em",
          xs: "36em",
          sm: "48em",
          md: "62em",
          lg: "75em",
          xl: "88em",
        },
      }}
      withGlobalStyles
      withNormalizeCSS>
      <AuthUserProvider>
        <App />
      </AuthUserProvider>
    </MantineProvider>
  </React.StrictMode>
);

reportWebVitals();
