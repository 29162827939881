import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import Cookies from "js-cookie";
import useFirebaseAuth from "../context/authuser/useFirebaseAuth";
import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  SelectItemProps,
  Text,
  TextInput,
} from "@mantine/core";
import emailStyles from "./emailStyles";
import { useForm } from "@mantine/form";
import countries from "../shared/countries";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const EmailRegisterForm = () => {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => !/^\S+@\S+$/.test(value),
      password: (value) => value.trim().length === 0,
    },
  });
  const { classes } = emailStyles();
  const navigate = useNavigate();

  const { loggedUser } = useFirebaseAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");

  const login = (data: any) => {
    setLoading(true);
    console.log("====================================");
    console.log("form data: ", data);
    console.log("====================================");

    signInWithEmailAndPassword(getAuth(), data.email, data.password)
      .then(async (user) => {
        console.log("====================================");
        console.log("USER REGISTERED SUCCESS: ", user);
        console.log("====================================");
        try {
          await updateDoc(doc(db, "users", user.user.uid), {
            lastLogin: new Date().getTime(),
          });

          Cookies.set("auth_token", user.user.uid);

          setLoading(false);
          setSuccessMsg("Inscription réussie, redirection en cours...");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } catch (e) {
          setLoading(false);
          setErrorMsg("Erreur, actualisez la page puis recommencez.");
          console.error("Error adding document: ", e);
        }
      })
      .catch((regError) => {
        setLoading(false);

        console.warn("USER REGISTERED ERROR: ", regError);
        switch (regError.code) {
          case "auth/invalid-email":
            setErrorMsg("E-mail invalide");
            break;
          case "auth/wrong-password":
            setErrorMsg(
              "Identifiants incorrects, avez vous oublié votre mot de passe?"
            );
            break;
          default:
            setErrorMsg(
              "Erreur inconue, réessayez ou contactez l'administrateur"
            );
            break;
        }
      });
  };
  const data = countries.map((item) => ({
    ...item,
    value: "+" + item.phone,
  }));
  interface ItemProps extends SelectItemProps {
    value: string;
    label: string;
    phone: string;
  }
  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, value, phone, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {/*  
          <img
            src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
            alt={label}
          />
*/}
          <div>
            <Text>{label}</Text>
            <Text size="xs" color="dimmed">
              +{phone}
            </Text>
          </div>
        </Group>
      </div>
    )
  );
  return (
    <>
      <form onSubmit={form.onSubmit((data) => login(data))}>
        <Box m={1}>
          <TextInput
            className={classes.inputs}
            name="email"
            type="email"
            label="Votre adresse mail"
            //  value={email}
            {...form.getInputProps("email")}
            //  onChange={(emailEvt: any) => setEmail(emailEvt.target.value)}
          />
          <TextInput
            className={classes.inputs}
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            //  value={password}
            {...form.getInputProps("password")}
            /*
            onChange={(passwordEvt: any) =>
              setPassword(passwordEvt.target.value)
            }
            */
          />
        </Box>
        <Text variant="body2" color="success">
          {successMsg}
        </Text>
        <Text variant="body2" color="error">
          {errorMsg}
        </Text>

        <Button className={classes.confirmButon} type="submit">
          Confirmer
        </Button>
      </form>
      {loading && (
        <>
          <LoadingOverlay
            style={{
              color: "#000000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              margin: "0 auto",
            }}
            visible={loading}
            overlayBlur={2}
            onClick={() => null}
          />
        </>
      )}
    </>
  );
};
export default EmailRegisterForm;
