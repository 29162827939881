import { createStyles } from "@mantine/core";
import { colors } from "../../configs";
const navLinksstyles = createStyles((theme) => ({
  linkText: {
    //    margin: "5px",
    textDecoration: "none",
    display: "block",
    padding: "6px 15px",
    fontSize: "16px",
    color: colors.primaryLight,
    fontWeight: "bold",
    borderRadius: "10px",
    transition: "0.4s",
    "&: hover": {
      //  border: "0.5px solid #F7D364",
      backgroundColor: colors.primary,
      color: "#FFFFFF",
    },

    [theme.fn.largerThan("xl")]: { fontSize: "16px", padding: "6px 15px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "16px", padding: "6px 15px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "16px", padding: "6px 7px" },
    [theme.fn.smallerThan("md")]: { fontSize: "16px", padding: "3px 7px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "15px", padding: "3px 7px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "13px", padding: "2px 5px" },
  },
}));

export default navLinksstyles;
