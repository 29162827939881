import React from "react";
import { Box, Text } from "@mantine/core";
import { colors } from "../../../configs";
import wusStyles from "../CheckWus/wusStyles";

const WusCat = ({ icon, texts, title }: any) => {
  const { classes } = wusStyles();

  return (
    <Box className={classes.wusStepWrapper}>
      <Text sx={{ color: colors.primary, fontWeight: "bold" }}>{title}</Text>
      <Box
        sx={{
          display: " flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            padding: "20px",
          }}>
          {icon}
        </Box>
        <Box>
          {texts.map((text: any, txtIdx: any) => (
            <Text key={txtIdx}>{`- ${text}`}</Text>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WusCat;
