import React from "react";
import { Box, Button, Group, rem, Text } from "@mantine/core";
import {
  IconCalendarDollar,
  IconCircleCheckFilled,
  IconCoin,
} from "@tabler/icons-react";
import { colors } from "../../configs";
import { Link } from "react-router-dom";
import useFirebaseAuth from "../../context/authuser/useFirebaseAuth";

const PaymentCard = ({ reqId }: any) => {
  const { loggedUser } = useFirebaseAuth();
  return (
    <Group
      position="center"
      sx={{ height: "50vh", display: "flex", flexDirection: "column" }}>
      <IconCircleCheckFilled
        color="blue"
        size="50"
        style={{ color: "green" }}
      />

      <Text
        sx={{
          fontSize: "27px",
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: "1.1",
          marginBottom: "20px",
          color: "green",
        }}>
        Votre demande a bien été enregistrée, veuillez procéder au payment des
        frais de dossier svp.
      </Text>
      <Text sx={{ fontSize: "27px", color: "white", fontWeight: "bold" }}>
        Payement des frais de dossiers (50.000 FCFA*)
        <Text sx={{ color: colors.secondary, fontSize: "11px" }}>
          *Frais unique.
        </Text>
      </Text>

      <Button
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://buy.stripe.com/test_6oEcPWcYi3OAfzW145"
        leftIcon={<IconCoin size={rem(18)} />}
        styles={(theme) => ({
          root: {
            backgroundColor: "#00acee",
            border: 0,
            height: rem(42),
            paddingLeft: rem(20),
            paddingRight: rem(20),
            "&:not([data-disabled])": theme.fn.hover({
              backgroundColor: theme.fn.darken("#00acee", 0.05),
            }),
          },

          leftIcon: {
            marginRight: theme.spacing.md,
          },
        })}>
        Payez maintenant
      </Button>
      <Link to="/profile/commandes" state={{ userId: loggedUser }}>
        <Button
          leftIcon={<IconCalendarDollar size={rem(18)} />}
          styles={(theme) => ({
            root: {
              border: "1px solid #00acee",
              height: rem(42),
              paddingLeft: rem(20),
              paddingRight: rem(20),
              "&:not([data-disabled])": theme.fn.hover({
                backgroundColor: theme.fn.darken("#00acee", 0.05),
              }),
            },

            leftIcon: {
              marginRight: theme.spacing.md,
            },
          })}>
          Payez plus tard.
        </Button>
      </Link>
    </Group>
  );
};

export default PaymentCard;
