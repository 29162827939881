import { Box, Button, Grid, SimpleGrid, Stack, Text } from "@mantine/core";
import React from "react";

import { Icon3dCubeSphere } from "@tabler/icons-react";
import { colors } from "../../../configs";
import HiwStep from "../acdcomponents/HiwStep";
import hwiStyles from "./hiwStyles";
const hiwSteps = [
  {
    text: "Répondez à un questionnaire en quelques minutes",
    icon: <Icon3dCubeSphere />,
    iconColor: "#07B1FB",
  },
  {
    text: "Nous réalisons l’ensemble de vos formalités auprès du ministère de la construction",
    icon: <Icon3dCubeSphere />,
    iconColor: "#0AE14B",
  },
  {
    text: "Nos experts s’occupent du montage de votre dossier",
    icon: <Icon3dCubeSphere />,
    iconColor: "#016699",
  },
  {
    text: "Vous obtenezvotre ACD dans les meilleurs délais",
    icon: <Icon3dCubeSphere />,
    iconColor: "#CD71AB",
  },
];
const AcdHiw = () => {
  const { classes } = hwiStyles();
  return (
    <Box className={classes.wrapper}>
      <Box>
        <Text className={classes.hiwTitle}>Comment ça marche ?</Text>
      </Box>
      <Box className={classes.hiwStepsbox}>
        <Box className={classes.hiwStepsGrid}>
          {hiwSteps.map((step, si) => (
            <HiwStep key={si} icon={step.icon} text={step.text} />
          ))}
        </Box>
      </Box>

      <Box>
        <Button
          component="a"
          href="/formulaire-demande-acd"
          className={classes.hiwActionBtn}>
          Démarrer une procédure
        </Button>
      </Box>
    </Box>
  );
};

export default AcdHiw;
