import React, { useState } from "react";
import {
  createStyles,
  Navbar,
  Group,
  Code,
  getStylesRef,
  rem,
  Box,
  Button,
  Drawer,
  Burger,
  ThemeIcon,
} from "@mantine/core";
import {
  IconBellRinging,
  IconSettings,
  IconLogout,
  IconUser,
  IconMenuOrder,
  IconMenu2,
} from "@tabler/icons-react";
import LOGO from "../../assets/images/TransLogo.jpeg";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useFirebaseAuth from "../../context/authuser/useFirebaseAuth";
import { useDisclosure } from "@mantine/hooks";
import { colors } from "../../configs";

const useStyles = createStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  navbar: {
    display: "flex",
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    [theme.fn.smallerThan("xs")]: { display: "none" },
    maxWidth: "200px",
  },
  mobileNavbar: {
    display: "none",
    [theme.fn.smallerThan("xs")]: { display: "flex" },
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    minWidth: "100%",
    height: "100%",
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  version: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background!,
      0.1
    ),
    color: theme.white,
    fontWeight: 700,
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background!,
      0.1
    )}`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background!,
      0.1
    )}`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.1
      ),
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.white,
    opacity: 0.75,
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.15
      ),
      [`& .${getStylesRef("icon")}`]: {
        opacity: 0.9,
      },
    },
  },
  menuToggle: {
    display: "none",
    [theme.fn.smallerThan("xs")]: { display: "flex" },
    margin: "5px",
  },
  drawer: {
    maxWidth: "200px",
    backgroundColor: theme.primaryColor,
  },
}));

const data = [
  { link: "", label: "Compte", icon: IconUser },
  { link: "notifs", label: "Notifications", icon: IconBellRinging },
  { link: "commandes", label: "Commandes", icon: IconMenuOrder },
  //  { link: "mot-de-passe", label: "Mot de passe", icon: IconPassword },
  //  { link: "modifier-profile", label: "Modifier", icon: IconUserEdit },
  { link: "reglages", label: "Réglages", icon: IconSettings },
];

const Profile = ({ navigation, router }: any) => {
  const [opened, { open, close }] = useDisclosure(false);

  const { loggedUser, logOut } = useFirebaseAuth();
  const navigate = useNavigate();

  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");

  const setActiveLabelAndCloseDrawer = (item: any) => {
    setActive(item.label);
    close();
  };
  const links = data.map((item) => (
    <Link
      state={{ userId: loggedUser }}
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        // event.preventDefault();
        setActiveLabelAndCloseDrawer(item);
      }}>
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </Link>
  ));
  const loginOut = () => {
    logOut()
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        alert("Impossible de vous déconnecter, réessayer");
      });
  };
  return (
    <>
      <Box className={classes.mainWrapper}>
        <Navbar
          height="100vh"
          // width={{ sm: 300 }}
          p="md"
          className={classes.navbar}>
          <Navbar.Section grow>
            <Group className={classes.header} position="apart">
              <img src={LOGO} width={50} alt="Trans Immo" />
              <Code className={classes.version}>v3.1.2</Code>
            </Group>
            {links}
          </Navbar.Section>

          <Navbar.Section className={classes.footer}>
            <Button
              className={classes.link}
              onClick={() => loginOut()}
              c="yellow">
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Se déconnecter</span>
            </Button>
          </Navbar.Section>
        </Navbar>

        <Drawer
          opened={opened}
          onClose={close}
          className={classes.drawer}
          title="Menu">
          <Navbar
            //  height="100vh"
            // width={{ sm: 300 }}
            p="md"
            className={classes.mobileNavbar}>
            <Navbar.Section grow>
              <Group className={classes.header} position="apart">
                <img src={LOGO} width={50} alt="Trans Immo" />
                <Code className={classes.version}>v3.1.2</Code>
              </Group>
              {links}
            </Navbar.Section>

            <Navbar.Section className={classes.footer}>
              <Button
                className={classes.link}
                onClick={() => loginOut()}
                c="yellow">
                <IconLogout className={classes.linkIcon} stroke={1.5} />
                <span>Se déconnecter</span>
              </Button>
            </Navbar.Section>
          </Navbar>
        </Drawer>

        <ThemeIcon
          size="xl"
          variant="filled"
          color={colors.primary}
          onClick={open}
          className={classes.menuToggle}>
          <IconMenu2 />
        </ThemeIcon>

        <Box className={classes.content}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export default Profile;
