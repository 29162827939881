import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
import navLinksstyles from "./navLinksStyles";

const NavLink = ({ title, link }: any) => {
  const { classes } = navLinksstyles();
  return (
    <Link to={`/${link}`} style={{ textDecoration: "none" }}>
      <Text className={classes.linkText}>{title}</Text>
    </Link>
  );
};

export default NavLink;
