import { createStyles, em, getBreakpointValue } from "@mantine/core";
import { colors } from "../../configs";
const footerStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    color: "white",
    backgroundColor: colors.primary,
    flexDirection: "column",
    padding: "30px 3%",
  },

  // starting css for the main(first) column box
  mainBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: 15,

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.xl) - 1)})`]:
      { flexDirection: "row" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.lg) - 1)})`]:
      { flexDirection: "row" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]:
      { flexDirection: "column" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      { flexDirection: "column" },
  },
  commonTitle: {
    fontSize: "24px",
    fontWeight: "bolder",
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]:
      { fontSize: "18px" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      { fontSize: "15px" },
  },
  commonText: {
    fontSize: "18px",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]:
      { fontSize: "15px" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      { fontSize: "13px" },
  },
  socialBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  socialIconWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]:
      { gap: 10 },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      { gap: 3 },
  },
  socialIcon: {
    width: "50px",
    height: "50px",
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]:
      { width: "35px", height: "35px" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      { width: "25px", height: "25px" },
  },
  siteName: {
    fontSize: "18px",

    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]:
      { fontSize: "15px" },
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]:
      { fontSize: "13px" },
  },
  contactItemBox: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
}));

export default footerStyles;
