import { createStyles } from "@mantine/core";
import { colors } from "../../../configs";
const faqStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
    backgroundColor: "#e2e9e8",
    marginBottom: "25px",
    padding: "30px 0",

    /*
    [theme.fn.largerThan("xl")]: { padding: "30px 8%" },
    [theme.fn.smallerThan("xl")]: { padding: "24px 8%" },
    [theme.fn.smallerThan("lg")]: { padding: "21px 8%" },
    [theme.fn.smallerThan("md")]: { padding: "18px 7px" },
    [theme.fn.smallerThan("sm")]: { padding: "15px 7px" },
        [theme.fn.smallerThan("xs")]: { padding: "15px 5px" },
    
        */
  },

  faqTitle: {
    fontSize: "36px",
    color: colors.primary,
    fontWeight: "bold",
    textAlign: "center",
    [theme.fn.smallerThan("sm")]: { fontSize: "30px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "24px" },
  },

  itemLabel: {
    color: colors.primary,
    fontWeight: "bold",
    [theme.fn.smallerThan("sm")]: { fontSize: "18px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "15px" },
  },
}));

export default faqStyles;
