import React from "react";
import { Box, Text } from "@mantine/core";
import {
  IconBrandWhatsapp,
  IconHeadphonesFilled,
  IconMail,
} from "@tabler/icons-react";
import headerBarStyles from "./headerBarStyles";

const HeaderBar = () => {
  const { classes } = headerBarStyles();
  return (
    <Box className={classes.wrapper}>
      <a
        href="mailto:contact@transparenceimmobiliere.com"
        className={classes.icnLink}>
        <IconMail />
        <Text className={classes.icnText}>
          :contact@transparenceimmobiliere.com
        </Text>
      </a>
      <a href="tel:+2250777144229" className={classes.icnLink}>
        <IconHeadphonesFilled />
        <Text className={classes.icnText}>:+2250777144229</Text>
      </a>
      <a href="https://wa.me/+2250777144229" className={classes.icnLink}>
        <IconBrandWhatsapp />
        <Text className={classes.icnText}>:+2250777144229</Text>
      </a>
    </Box>
  );
};

export default HeaderBar;
