import { createStyles } from "@mantine/core";
import { colors, sizes } from "../../configs";
const ServicesExplanationStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.fn.largerThan("xl")]: { padding: sizes.supXl_section_pd },
    [theme.fn.smallerThan("xl")]: { padding: sizes.xl_section_pd },
    [theme.fn.smallerThan("lg")]: { padding: sizes.lg_section_pd },
    [theme.fn.smallerThan("md")]: { padding: sizes.md_section_pd },
    [theme.fn.smallerThan("sm")]: {
      padding: sizes.sm_section_pd,
    },
    [theme.fn.smallerThan("xs")]: {
      padding: sizes.xs_section_pd,
    },
  },

  title: {
    fontWeight: "bold",
    lineHeight: 1,
    marginBottom: theme.spacing.md,
    color: colors.primary,
    textAlign: "center",
    [theme.fn.largerThan("xl")]: { fontSize: sizes.supXl_title },
    [theme.fn.smallerThan("xl")]: { fontSize: sizes.xl_title },
    [theme.fn.smallerThan("lg")]: { fontSize: sizes.lg_title },
    [theme.fn.smallerThan("md")]: { fontSize: sizes.md_title },
    [theme.fn.smallerThan("sm")]: { fontSize: sizes.sm_title },
    [theme.fn.smallerThan("xs")]: { fontSize: sizes.xs_title },
  },
  description: {
    color: colors.primary,
    textAlign: "center",
    [theme.fn.largerThan("xl")]: { fontSize: sizes.supXl_desc },
    [theme.fn.smallerThan("xl")]: { fontSize: sizes.xl_desc },
    [theme.fn.smallerThan("lg")]: { fontSize: sizes.lg_desc },
    [theme.fn.smallerThan("md")]: { fontSize: sizes.md_desc },
    [theme.fn.smallerThan("sm")]: { fontSize: sizes.sm_desc },
    [theme.fn.smallerThan("xs")]: { fontSize: sizes.xs_desc },
  },
}));

export default ServicesExplanationStyles;
