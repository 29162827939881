import { createStyles, rem } from "@mantine/core";
import { colors } from "../../../configs";
const heroStyles = createStyles((theme) => ({
  wrapper: {
    padding: "2% 8%",
    backgroundColor: colors.secondary,

    [theme.fn.largerThan("xl")]: { padding: "2%  8%" },
    [theme.fn.smallerThan("xl")]: { padding: "2%  8%" },
    [theme.fn.smallerThan("lg")]: { padding: "2%  8%" },
    [theme.fn.smallerThan("md")]: { padding: "2%  7%" },
    [theme.fn.smallerThan("sm")]: { padding: "2%  7%" },
    [theme.fn.smallerThan("xs")]: {
      padding: "5% 2%",
    },
  },
  subWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 15,
  },
  left: {
    flex: 1,

    [theme.fn.smallerThan("sm")]: { display: "none" },
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
  right: { flex: 2 },
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(30),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: colors.primary,
    [theme.fn.smallerThan("sm")]: { fontSize: "24px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "24px" },
  },
  subTitle: {
    color: colors.primary,
    fontSize: "21px",
    [theme.fn.smallerThan("sm")]: { fontSize: "15px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "12px" },
  },
}));

export default heroStyles;
