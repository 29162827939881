import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import Notification from "../models/Notification";

const sendNotifs = async (notif: Notification) => {
  await addDoc(collection(db, "notifs"), notif);
};
const fetchNotifs = () => {};
const readNotifs = () => {};
export { sendNotifs, fetchNotifs, readNotifs };
