const cities = [
  {
    label: "Zuénoula",
    lat: "7.4303",
    lng: "-6.0505",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "zuénoula",
  },
  {
    label: "Zoukougbeu",
    lat: "6.7623",
    lng: "-6.8638",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "zoukougbeu",
  },
  {
    label: "Zouan-Hounien",
    lat: "6.9194",
    lng: "-8.2065",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "zouan_hounien",
  },
  {
    label: "Yamoussoukro",
    lat: "6.8161",
    lng: "-5.2742",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Yamoussoukro",
    capital: "primary",
    population: "355573",
    population_proper: "355573",
    value: "yamoussoukro",
  },
  {
    label: "Yakassé-Attobrou",
    lat: "6.1817",
    lng: "-3.6511",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "yakassé_attobrou",
  },
  {
    label: "Vavoua",
    lat: "7.3819",
    lng: "-6.4778",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "vavoua",
  },
  {
    label: "Téhini",
    lat: "9.6054",
    lng: "-3.6580",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "téhini",
  },
  {
    label: "Transua",
    lat: "7.5500",
    lng: "-3.0143",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "transua",
  },
  {
    label: "Toumodi",
    lat: "6.5520",
    lng: "-5.0190",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "39005",
    population_proper: "39005",
    value: "toumodi",
  },
  {
    label: "Toulépleu",
    lat: "6.5797",
    lng: "-8.4109",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "toulépleu",
  },
  {
    label: "Touba",
    lat: "8.2833",
    lng: "-7.6833",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "31844",
    population_proper: "31844",
    value: "touba",
  },
  {
    label: "Tiébissou",
    lat: "7.1577",
    lng: "-5.2248",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "tiébissou",
  },
  {
    label: "Tiassalé",
    lat: "5.8984",
    lng: "-4.8229",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "tiassalé",
  },
  {
    label: "Tiapoum",
    lat: "5.1362",
    lng: "-3.0231",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "tiapoum",
  },
  {
    label: "Tengréla",
    lat: "10.4811",
    lng: "-6.4069",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "tengréla",
  },
  {
    label: "Taï",
    lat: "5.8737",
    lng: "-7.4552",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "taï",
  },
  {
    label: "Tanda",
    lat: "7.8034",
    lng: "-3.1683",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "tanda",
  },
  {
    label: "Tabou",
    lat: "4.4230",
    lng: "-7.3528",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "tabou",
  },
  {
    label: "Taabo",
    lat: "6.1998",
    lng: "-5.1088",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "taabo",
  },
  {
    label: "Séguélon",
    lat: "9.3569",
    lng: "-7.1208",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "séguélon",
  },
  {
    label: "Séguéla",
    lat: "7.9611",
    lng: "-6.6731",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "admin",
    population: "51157",
    population_proper: "12603",
    value: "séguéla",
  },
  {
    label: "Soubré",
    lat: "5.7836",
    lng: "-6.5939",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "108933",
    population_proper: "58492",
    value: "soubré",
  },
  {
    label: "Songon",
    lat: "5.3196",
    lng: "-4.2542",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Abidjan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "songon",
  },
  {
    label: "Sipilou",
    lat: "7.8667",
    lng: "-8.1000",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "sipilou",
  },
  {
    label: "Sinématiali",
    lat: "9.5841",
    lng: "-5.3848",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "sinématiali",
  },
  {
    label: "Sinfra",
    lat: "6.6210",
    lng: "-5.9114",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "sinfra",
  },
  {
    label: "Sikensi",
    lat: "5.6683",
    lng: "-4.5737",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "sikensi",
  },
  {
    label: "Sassandra",
    lat: "4.9504",
    lng: "-6.0833",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "38411",
    population_proper: "23274",
    value: "sassandra",
  },
  {
    label: "Sandégué",
    lat: "7.9538",
    lng: "-3.5801",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "sandégué",
  },
  {
    label: "San-Pédro",
    lat: "4.7704",
    lng: "-6.6400",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "admin",
    population: "210273",
    population_proper: "196751",
    value: "san_pédro",
  },
  {
    label: "Samatiguila",
    lat: "9.8195",
    lng: "-7.5608",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "samatiguila",
  },
  {
    label: "Sakassou",
    lat: "7.4546",
    lng: "-5.2926",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "sakassou",
  },
  {
    label: "Prikro",
    lat: "7.6470",
    lng: "-3.9963",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "prikro",
  },
  {
    label: "Oumé",
    lat: "6.3833",
    lng: "-5.4167",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Gôh-Djiboua",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "oumé",
  },
  {
    label: "Ouaninou",
    lat: "8.2379",
    lng: "-7.8664",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "ouaninou",
  },
  {
    label: "Ouangolodougou",
    lat: "9.9684",
    lng: "-5.1488",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "ouangolodougou",
  },
  {
    label: "Odienné",
    lat: "9.5000",
    lng: "-7.5667",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "admin",
    population: "49857",
    population_proper: "19119",
    value: "odienné",
  },
  {
    label: "Niakaramandougou",
    lat: "8.6576",
    lng: "-5.2911",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "niakaramandougou",
  },
  {
    label: "Nassian",
    lat: "8.4527",
    lng: "-3.4715",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "nassian",
  },
  {
    label: "M’Bengué",
    lat: "10.0024",
    lng: "-5.9004",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "m’bengué",
  },
  {
    label: "M’Batto",
    lat: "6.4720",
    lng: "-4.3578",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "m’batto",
  },
  {
    label: "M’Bahiakro",
    lat: "7.4573",
    lng: "-4.3399",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "m’bahiakro",
  },
  {
    label: "Méagui",
    lat: "5.4048",
    lng: "-6.5584",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "méagui",
  },
  {
    label: "Minignan",
    lat: "9.9974",
    lng: "-7.8359",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "minignan",
  },
  {
    label: "Mankono",
    lat: "8.0586",
    lng: "-6.1897",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "mankono",
  },
  {
    label: "Man",
    lat: "7.4004",
    lng: "-7.5500",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "admin",
    population: "146974",
    population_proper: "139341",
    value: "man",
  },
  {
    label: "Maféré",
    lat: "5.4151",
    lng: "-3.0301",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "maféré",
  },
  {
    label: "Madinani",
    lat: "9.6108",
    lng: "-6.9422",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "madinani",
  },
  {
    label: "Lakota",
    lat: "5.8500",
    lng: "-5.6833",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Gôh-Djiboua",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "lakota",
  },
  {
    label: "Kouto",
    lat: "9.8903",
    lng: "-6.4092",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kouto",
  },
  {
    label: "Kounahiri",
    lat: "7.7909",
    lng: "-5.8348",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kounahiri",
  },
  {
    label: "Koun-Fao",
    lat: "7.4876",
    lng: "-3.2525",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "koun_fao",
  },
  {
    label: "Kouibly",
    lat: "7.2560",
    lng: "-7.2351",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kouibly",
  },
  {
    label: "Kouassi-Kouassikro",
    lat: "7.3414",
    lng: "-4.6771",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kouassi_kouassikro",
  },
  {
    label: "Koro",
    lat: "8.5550",
    lng: "-7.4635",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "koro",
  },
  {
    label: "Korhogo",
    lat: "9.4578",
    lng: "-5.6294",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "admin",
    population: "286071",
    population_proper: "286071",
    value: "korhogo",
  },
  {
    label: "Kong",
    lat: "9.1506",
    lng: "-4.6103",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kong",
  },
  {
    label: "Katiola",
    lat: "8.1333",
    lng: "-5.1000",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "katiola",
  },
  {
    label: "Kaniasso",
    lat: "9.8147",
    lng: "-7.5125",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kaniasso",
  },
  {
    label: "Kani",
    lat: "8.4781",
    lng: "-6.6051",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "kani",
  },
  {
    label: "Jacqueville",
    lat: "5.2000",
    lng: "-4.4167",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "jacqueville",
  },
  {
    label: "Issia",
    lat: "6.4922",
    lng: "-6.5856",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "issia",
  },
  {
    label: "Guéyo",
    lat: "5.6882",
    lng: "-6.0712",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "guéyo",
  },
  {
    label: "Guitry",
    lat: "5.5195",
    lng: "-5.2404",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Gôh-Djiboua",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "guitry",
  },
  {
    label: "Guiglo",
    lat: "6.5436",
    lng: "-7.4933",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "63643",
    population_proper: "63643",
    value: "guiglo",
  },
  {
    label: "Grand-Lahou",
    lat: "5.1333",
    lng: "-5.0167",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "grand_lahou",
  },
  {
    label: "Grand-Bassam",
    lat: "5.2000",
    lng: "-3.7333",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "73772",
    population_proper: "48681",
    value: "grand_bassam",
  },
  {
    label: "Gbéléban",
    lat: "9.5833",
    lng: "-8.1333",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Denguélé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "gbéléban",
  },
  {
    label: "Gagnoa",
    lat: "6.1333",
    lng: "-5.9333",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Gôh-Djiboua",
    capital: "admin",
    population: "123184",
    population_proper: "99192",
    value: "gagnoa",
  },
  {
    label: "Fresco",
    lat: "5.0823",
    lng: "-5.5694",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "fresco",
  },
  {
    label: "Ferkessédougou",
    lat: "9.5928",
    lng: "-5.1944",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "62008",
    population_proper: "52812",
    value: "ferkessédougou",
  },
  {
    label: "Facobly",
    lat: "7.3883",
    lng: "-7.3764",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "facobly",
  },
  {
    label: "Duekoué",
    lat: "6.7419",
    lng: "-7.3492",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "duekoué",
  },
  {
    label: "Doropo",
    lat: "9.8103",
    lng: "-3.3450",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "doropo",
  },
  {
    label: "Djékanou",
    lat: "6.4839",
    lng: "-5.1155",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "djékanou",
  },
  {
    label: "Divo",
    lat: "5.8372",
    lng: "-5.3572",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Gôh-Djiboua",
    capital: "minor",
    population: "127867",
    population_proper: "127867",
    value: "divo",
  },
  {
    label: "Dimbokro",
    lat: "6.6505",
    lng: "-4.7100",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "admin",
    population: "67349",
    population_proper: "25586",
    value: "dimbokro",
  },
  {
    label: "Dikodougou",
    lat: "9.0676",
    lng: "-5.7722",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "dikodougou",
  },
  {
    label: "Didiévi",
    lat: "7.1287",
    lng: "-4.8980",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "didiévi",
  },
  {
    label: "Dianra",
    lat: "8.9433",
    lng: "-6.2549",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Woroba",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "dianra",
  },
  {
    label: "Daoukro",
    lat: "7.0586",
    lng: "-3.9646",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "daoukro",
  },
  {
    label: "Danané",
    lat: "7.2596",
    lng: "-8.1550",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "danané",
  },
  {
    label: "Daloa",
    lat: "6.8900",
    lng: "-6.4500",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "admin",
    population: "255168",
    population_proper: "215652",
    value: "daloa",
  },
  {
    label: "Dabou",
    lat: "5.3256",
    lng: "-4.3767",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "admin",
    population: "72913",
    population_proper: "69661",
    value: "dabou",
  },
  {
    label: "Dabakala",
    lat: "8.3667",
    lng: "-4.4333",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "dabakala",
  },
  {
    label: "Béttié",
    lat: "6.0757",
    lng: "-3.4085",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "béttié",
  },
  {
    label: "Béoumi",
    lat: "7.6740",
    lng: "-5.5809",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "béoumi",
  },
  {
    label: "Buyo",
    lat: "6.2753",
    lng: "-6.9970",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Bas-Sassandra",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "buyo",
  },
  {
    label: "Boundiali",
    lat: "9.5167",
    lng: "-6.4833",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Savanes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "boundiali",
  },
  {
    label: "Bouna",
    lat: "9.2667",
    lng: "-3.0000",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "bouna",
  },
  {
    label: "Bouaké",
    lat: "7.6833",
    lng: "-5.0331",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "admin",
    population: "659233",
    population_proper: "659233",
    value: "bouaké",
  },
  {
    label: "Bouaflé",
    lat: "6.9903",
    lng: "-5.7442",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Sassandra-Marahoué",
    capital: "minor",
    population: "71792",
    population_proper: "71792",
    value: "bouaflé",
  },
  {
    label: "Botro",
    lat: "7.8525",
    lng: "-5.3106",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Vallée du Bandama",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "botro",
  },
  {
    label: "Bonoua",
    lat: "5.2725",
    lng: "-3.5962",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "bonoua",
  },
  {
    label: "Bongouanou",
    lat: "6.6517",
    lng: "-4.2041",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "bongouanou",
  },
  {
    label: "Bondoukou",
    lat: "8.0400",
    lng: "-2.8000",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Zanzan",
    capital: "admin",
    population: "58297",
    population_proper: "18706",
    value: "bondoukou",
  },
  {
    label: "Bocanda",
    lat: "7.0626",
    lng: "-4.4995",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "bocanda",
  },
  {
    label: "Bloléquin",
    lat: "6.5691",
    lng: "-8.0025",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "bloléquin",
  },
  {
    label: "Bingerville",
    lat: "5.3500",
    lng: "-3.9000",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Abidjan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "bingerville",
  },
  {
    label: "Biankouma",
    lat: "7.7333",
    lng: "-7.6167",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "biankouma",
  },
  {
    label: "Bangolo",
    lat: "7.0123",
    lng: "-7.4864",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Montagnes",
    capital: "admin",
    population: "",
    population_proper: "",
    value: "bangolo",
  },
  {
    label: "Azaguié",
    lat: "5.6298",
    lng: "-4.0820",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "azaguié",
  },
  {
    label: "Ayamé",
    lat: "5.6052",
    lng: "-3.1571",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "ayamé",
  },
  {
    label: "Attiégouakro",
    lat: "6.7740",
    lng: "-5.1141",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Yamoussoukro",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "attiégouakro",
  },
  {
    label: "Arrah",
    lat: "6.6734",
    lng: "-3.9694",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lacs",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "arrah",
  },
  {
    label: "Anyama",
    lat: "5.4946",
    lng: "-4.0518",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Abidjan",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "anyama",
  },
  {
    label: "Alépé",
    lat: "5.5004",
    lng: "-3.6631",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "alépé",
  },
  {
    label: "Akoupé",
    lat: "6.3842",
    lng: "-3.8876",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "akoupé",
  },
  {
    label: "Agou",
    lat: "5.9829",
    lng: "-3.9439",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "agou",
  },
  {
    label: "Agnibilékrou",
    lat: "7.1311",
    lng: "-3.2041",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "agnibilékrou",
  },
  {
    label: "Agboville",
    lat: "5.9333",
    lng: "-4.2167",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "81770",
    population_proper: "64285",
    value: "agboville",
  },
  {
    label: "Adzopé",
    lat: "6.1035",
    lng: "-3.8648",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Lagunes",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "adzopé",
  },
  {
    label: "Adiaké",
    lat: "5.2863",
    lng: "-3.3040",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "",
    population_proper: "",
    value: "adiaké",
  },
  {
    label: "Aboisso",
    lat: "5.4667",
    lng: "-3.2000",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "minor",
    population: "37654",
    population_proper: "37654",
    value: "aboisso",
  },
  {
    label: "Abidjan",
    lat: "5.3364",
    lng: "-4.0267",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Abidjan",
    capital: "primary",
    population: "4980000",
    population_proper: "4980000",
    value: "abidjan",
  },
  {
    label: "Abengourou",
    lat: "6.7297",
    lng: "-3.4964",
    country: "Côte D’Ivoire",
    iso2: "CI",
    admin_name: "Comoé",
    capital: "admin",
    population: "104020",
    population_proper: "71598",
    value: "abengourou",
  },
];

export default cities;
