import React from "react";
import {
  createStyles,
  Image,
  Title,
  Button,
  Group,
  List,
  ThemeIcon,
  rem,
  Box,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import image from "../../../assets/images/TempCover1.png";
import { colors } from "../../../configs";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
    paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

const ManageHero = () => {
  const { classes } = useStyles();
  return (
    <div>
      <Box sx={{ padding: "0 10%", backgroundColor: colors.secondaryLight }}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Des <span className={classes.highlight}>Biens</span> à faire louer
              ?
            </Title>

            {/*  
                      
                                  <Text color="dimmed" mt="md">
                          Build fully functional accessible web applications faster than
                          ever – Mantine includes more than 120 customizable components and
                          hooks to cover you in any situation
            </Text>
                      */}

            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={
                <ThemeIcon size={20} radius="xl">
                  <IconCheck size={rem(12)} stroke={1.5} />
                </ThemeIcon>
              }>
              <List.Item>
                <b>Maisons:</b> – Appartement, villa,duplex,triplex,magasins
                etc...
              </List.Item>
              <List.Item>
                <b>Espaces</b> – Terrain, parcelle, espaces... etc
              </List.Item>
              <List.Item>
                <b>Autre:</b> – Stations,
              </List.Item>
            </List>

            <Group mt={30}>
              <Button
                radius="xl"
                size="md"
                component="a"
                href="/formulaire-gestion-locative"
                className={classes.control}
                sx={{ backgroundColor: colors.primary }}>
                Je démarre
              </Button>
            </Group>
          </div>
          <Image src={image} className={classes.image} />
        </div>
      </Box>
    </div>
  );
};
export default ManageHero;
