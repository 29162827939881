import React from "react";
import { Box } from "@mantine/core";
import ibcardStyles from "./ibcarStyles";

const IBCard = ({ text1, text2, icon, bgColor }: any) => {
  const { classes } = ibcardStyles();
  return (
    <Box className={classes.wrapper}>
      <div
        className={classes.ibcIcon}
        style={{
          backgroundColor: bgColor,
        }}>
        {icon}
      </div>
      <div className={classes.ibcText}>{text1}</div>
      <div className={classes.ibcText}>{text2}</div>
    </Box>
  );
};

export default IBCard;
