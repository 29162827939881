import { createStyles, rem } from "@mantine/core";
import { colors } from "../../configs";

const registerStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",

    minHeight: "100vh",
  },
  logo: {
    display: "flex",
    alignSelf: "center",
    margin: "0 auto",
    borderRadius: "25%",
    marginBottom: "30px",
  },
  left: {
    flex: 1.5,
    backgroundColor: colors.secondary,
    padding: "0 10px",
    //   paddingTop: rem(20),
    //    maxWidth: rem(450),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.fn.smallerThan("lg")]: {
      flex: 2,
    },
    [theme.fn.smallerThan("md")]: {
      flex: 2,
    },
    [theme.fn.smallerThan("sm")]: {
      flex: 2,
    },
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },
  right: {
    flex: 4,
    display: "flex",
    backgroundSize: "cover",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
    [theme.fn.smallerThan("md")]: {
      flex: 3,
    },
    [theme.fn.smallerThan("md")]: {
      flex: 3,
    },
    [theme.fn.smallerThan("sm")]: {
      flex: 2.5,
    },
    [theme.fn.smallerThan("xs")]: {
      flex: 0,
    },
  },

  top: {
    //  flex: 1.5,
  },
  center: {
    //  flex: 4,
  },
  bottom: {
    //  flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "2%",
  },
  form: {
    backgroundColor: colors.primary,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: "21px",
    color: colors.primary,
  },
  helpText: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: "18px",
    color: "white",
    fontWeight: "normal",
  },
  bottomText: {
    fontSize: "15px",
  },
  bottomLink: {
    fontSize: "15px",
    color: colors.primary,
  },
}));
export default registerStyles;
