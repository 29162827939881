import React, { useEffect, useState } from "react";
import { Box, Text } from "@mantine/core";

import { colors } from "../../../configs";
import UserCard from "./UserCard/UserCard";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase-config";
import { useLocation } from "react-router-dom";

const Compte = () => {
  const location = useLocation();
  const { userId } = location.state;
  const [user, setUser] = useState<any | null>(null);
  console.log("====================================");
  console.log("LOGGED USER: ", userId);
  console.log("====================================");

  const getUserData = async () => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const user = docSnap.data();

      setUser(user);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    } else {
      setUser(null);
    }
  }, []);
  return (
    <>
      <Box sx={{ dysplay: "flex", flexDirection: "row" }}>
        {user && (
          <>
            <Text
              sx={{
                flex: 1,
                color: colors.secondary,
                fontSize: "30px",
                marginBottom: "15px",
              }}>
              Bonjour {`${user.firstname} ${user.name}`}
            </Text>
          </>
        )}
      </Box>

      <Box
        sx={{
          width: "90%",
          backgroundColor: colors.primary,
          padding: "30px",
          borderRadius: "15px",
        }}>
        {user ? (
          <>
            <UserCard data={user} />
          </>
        ) : (
          <Text sx={{ color: "white" }}>Vous devez vous connecter</Text>
        )}
      </Box>
    </>
  );
};

export default Compte;
