import { createStyles } from "@mantine/styles";
import { colors } from "../../../configs";

const expertStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    padding: "20px 10% 70px 10%",
    gap: 25,

    [theme.fn.largerThan("xl")]: { padding: "20px 10% 70px 10%" },
    [theme.fn.smallerThan("xl")]: { padding: "20px 10% 70px 10%" },
    [theme.fn.smallerThan("lg")]: { padding: "20px 10% 70px 10%" },
    [theme.fn.smallerThan("md")]: { padding: "15px 5% 40px 5%" },
    [theme.fn.smallerThan("sm")]: { padding: "10px 5% 20px 5%" },
    [theme.fn.smallerThan("xs")]: { padding: "10px 5% 20px 5%" },
  },

  leftBox: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flexx-start",
    [theme.fn.largerThan("xl")]: { flex: 3 },
    [theme.fn.smallerThan("xl")]: { flex: 3 },
    [theme.fn.smallerThan("lg")]: { flex: 2.5 },
    [theme.fn.smallerThan("md")]: { flex: 2.5 },
    [theme.fn.smallerThan("sm")]: { flex: 2 },
    [theme.fn.smallerThan("xs")]: { flex: 1 },
  },
  rightBox: {
    flex: 1.5,
    maxHeight: "300px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.fn.largerThan("xl")]: { flex: 1.5 },
    [theme.fn.smallerThan("xl")]: { flex: 1.5 },
    [theme.fn.smallerThan("lg")]: { flex: 1.5 },
    [theme.fn.smallerThan("md")]: { flex: 1.5 },
    [theme.fn.smallerThan("sm")]: { display: "flex", flex: 1.5 },
    [theme.fn.smallerThan("xs")]: { display: "none", flex: 0 },
  },
  leftBoxTitle: {
    fontSize: "32px",
    color: colors.primary,
    fontWeight: "bold",
    lineHeight: "1.1",
    marginBottom: "10px",
  },
  leftBoxSubTitle: {
    fontSize: "28px",
    color: colors.primary,
    fontWeight: "normal",
    lineHeight: "1.1",

    marginBottom: "15px",
  },
  leftBoxText: {
    fontSize: "20px",
    color: colors.primary,

    marginBottom: "15px",
  },
}));

export default expertStyles;
