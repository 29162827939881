import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBhOatPDOcORNnUT_JxU4EFIOZjTp2-jxU",
  authDomain: "debara-10805.firebaseapp.com",
  projectId: "debara-10805",
  storageBucket: "debara-10805.appspot.com",
  messagingSenderId: "907291000173",
  appId: "1:907291000173:web:b04b28e1fd3e93f6172e64",
  measurementId: "G-XWBZ9GXT0J",
};
/*
const selectedLang = localStorage.getItem('i18nConfig')
const lang = selectedLang ? JSON.parse(selectedLang).selectedLang : 'fr'
*/
const app = initializeApp(firebaseConfig);
const authentication = getAuth(app);
authentication.languageCode = "fr";

const db = getFirestore(app);
const storage = getStorage(app);
export { authentication, db, storage };
