import React, { forwardRef, useState } from "react";
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Box,
  Paper,
  Text,
  Select,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { addDoc, collection } from "firebase/firestore";

import Navbar from "../components/Navbar/Navbar";
import { colors } from "../configs";
import { db } from "../firebase-config";
import formForSellStyles from "./formForSellstyles";
import { IconBulbFilled, IconChecks, IconStar } from "@tabler/icons-react";
import cities from "../shared/citiies";
import communes from "../shared/communes";
import useFirebaseAuth from "../context/authuser/useFirebaseAuth";
import PaymentCard from "../components/PaymentCard/PaymentCard";

const FormForSell = () => {
  const { loggedUser } = useFirebaseAuth();
  const { classes } = formForSellStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [reqId, setReqId] = useState<string | null>(null);
  const form = useForm({
    initialValues: {
      //emplacement
      type: "", // quel type de bien vendez vous
      category: "", // is the selling an appart, hotel, duplex...
      ville: "",
      commune: "",
      surfaceTotal: "",
      surfaceUsed: "",
      buildYear: "",

      value: "", //an estimated value,
      rooms: "",
      description: "",
    },
    validate: {
      //  name: (value) => value.trim().length < 2,
      //  email: (value) => !/^\S+@\S+$/.test(value),
      // subject: (value) => value.trim().length === 0,
    },
  });

  const sendInfo = async (reqData: any) => {
    setLoading(true);

    reqData.requesterId = loggedUser;
    reqData.country = "CI";
    reqData.allowedBailifId = "ADMIN"; //should be replace by an admin's id
    reqData.model = "vente";
    reqData.status = 0;
    reqData.createdAt = new Date().getTime();
    reqData.docsList = [];

    console.log("====================================");
    console.warn("THE INFORMATION DATA: ", reqData);
    console.log("====================================");

    await addDoc(collection(db, "requests"), reqData)
      .then((docRef: any) => {
        console.log("Document written with ID: ", docRef.id);
        setReqId(docRef.id);
        setSuccessMsg(
          "Votre demande a été enregistrée, nos experts vous recontacterons dans de plus brefs délais"
        );
        setLoading(false);
      })
      .catch((saveError: any) => {
        setReqId(null);
        console.error("ERROR SAVING YOUR REQUEST: ", saveError);
        setErrorMsg(
          "Désolé nous n'avons pas pu enregistrer votre demande réessayer si l'erruer persiste vérifier votre connexion ou réactualisez la page avant de recommencer."
        );
        setLoading(false);
      });
  };
  const styles = {
    inputs: {
      margin: "5px 0",
      label: {
        fontSize: "14px",
        color: "white",
      },
      description: { color: colors.secondary },
    },
  };
  return (
    <>
      <Navbar />
      <Box className={classes.wrapper}>
        <Box className={classes.left} sx={{ display: reqId ? "none" : "flex" }}>
          <Paper className={classes.descBox}>
            <IconBulbFilled
              size={50}
              className={classes.descIcon}
              style={{ color: "pink" }}
            />
            <Text className={classes.descTitle}>Précis et clair.</Text>
            <Text className={classes.descText}>
              Des données claires et concises facilitent et accélèrent le
              traitement de votre demande.
            </Text>
          </Paper>
          <Paper className={classes.descBox}>
            <IconChecks
              size={50}
              className={classes.descIcon}
              style={{ color: "green" }}
            />
            <Text className={classes.descTitle}> Correctes</Text>
            <Text className={classes.descText}>
              Des informations nous aident à mieux évaluer avec exactitude la
              valeur de votre bien.
            </Text>
          </Paper>
          <Paper className={classes.descBox}>
            <IconStar
              size={50}
              className={classes.descIcon}
              style={{ color: "red" }}
            />
            <Text className={classes.descTitle}>Description</Text>
            <Text className={classes.descText}>
              Une bonne description peut renforcer la précisions sur les
              informations saisies.
            </Text>
          </Paper>
        </Box>
        {reqId == null && reqId != "" ? (
          <Box className={classes.right}>
            <form onSubmit={form.onSubmit((data) => sendInfo(data))}>
              <Title
                order={2}
                size="h2"
                weight={900}
                align="center"
                color="yellow">
                Remplissez ce formulaire pour mettre en vente un bien.
              </Title>
              <SimpleGrid
                cols={2}
                mt="sm"
                breakpoints={[
                  { maxWidth: "sm", cols: 2 },
                  { maxWidth: "xs", cols: 1 },
                ]}>
                <Select
                  withAsterisk
                  id="type"
                  name="type"
                  label="Type de bien"
                  placeholder="Choisir une option"
                  data={["Construction", "Terrain", "Local", "Parcelle"]}
                  {...form.getInputProps("type")}
                  styles={(theme) => styles.inputs}
                />
                <Select
                  disabled={form.values.type !== "Construction"}
                  id="category"
                  name="category"
                  label="Catégorie"
                  placeholder="Choisir une option"
                  data={[
                    "Lot",
                    "Hotel",
                    "Villa",
                    "Duplex",
                    "Triplex",
                    "Immeuble",
                    "Bar",
                    "Maquis",
                    "Plein air",
                  ]}
                  {...form.getInputProps("category")}
                  styles={(theme) => styles.inputs}
                />
              </SimpleGrid>
              <SimpleGrid
                cols={2}
                mt="sm"
                breakpoints={[
                  { maxWidth: "sm", cols: 2 },
                  { maxWidth: "xs", cols: 1 },
                ]}>
                <Select
                  withAsterisk
                  name="ville"
                  searchable
                  label="Ville"
                  description="La ville où est situé le bien"
                  placeholder="choisir"
                  nothingFound="Aucune correspondance"
                  data={cities}
                  clearable
                  {...form.getInputProps("ville")}
                  styles={(theme) => styles.inputs}
                />
                <Select
                  disabled={form.values.ville !== "abidjan"}
                  name="commune"
                  searchable
                  label="Commune"
                  description="Uniquement si le bien est à Abidjan"
                  placeholder="choisir"
                  nothingFound="Aucune correspondance"
                  data={communes}
                  clearable
                  {...form.getInputProps("commune")}
                  styles={(theme) => styles.inputs}
                />
              </SimpleGrid>
              <SimpleGrid
                cols={2}
                mt="sm"
                breakpoints={[
                  { maxWidth: "sm", cols: 2 },
                  { maxWidth: "xs", cols: 1 },
                ]}>
                <TextInput
                  label="Superficie totale(mètres carrés)"
                  placeholder="ex: 600"
                  type="number"
                  name="surfaceTotal"
                  variant="filled"
                  {...form.getInputProps("surfaceTotal")}
                  styles={(theme) => styles.inputs}
                />
                <TextInput
                  label="Surface occupée par le bien(mètres carrés)"
                  placeholder="Exemple: 240"
                  type="number"
                  name="surfaceUsed"
                  variant="filled"
                  {...form.getInputProps("surfaceUsed")}
                  styles={(theme) => styles.inputs}
                />
              </SimpleGrid>
              <SimpleGrid
                cols={3}
                mt="sm"
                breakpoints={[
                  { maxWidth: "sm", cols: 3 },
                  { maxWidth: "xs", cols: 1 },
                ]}>
                <TextInput
                  label="Nombre de pièces"
                  placeholder="ex: 4"
                  type="number"
                  name="rooms"
                  variant="filled"
                  {...form.getInputProps("rooms")}
                  styles={(theme) => styles.inputs}
                />
                <TextInput
                  label="Valeur estimée(en FCFA)"
                  placeholder="Exemple: 6000000"
                  type="number"
                  name="value"
                  variant="filled"
                  {...form.getInputProps("value")}
                  styles={(theme) => styles.inputs}
                />
                <TextInput
                  label="Année de construction"
                  placeholder="Exemple: 1994"
                  type="number"
                  name="buildYear"
                  variant="filled"
                  {...form.getInputProps("buildYear")}
                  styles={(theme) => styles.inputs}
                />
              </SimpleGrid>
              <Textarea
                mt="md"
                label="Description du bien"
                placeholder="Décrivez en brièvement le bien mis en vente"
                maxRows={10}
                minRows={5}
                autosize
                name="description"
                variant="filled"
                {...form.getInputProps("description")}
                styles={(theme) => styles.inputs}
              />
              <Group
                mt="sm"
                position="left"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}>
                <Text style={{ color: "green" }}>{successMsg}</Text>
                <Text style={{ color: "red" }}>{errorMsg}</Text>
                <Button
                  disabled={loading}
                  type="submit"
                  size="md"
                  sx={{
                    minWidth: "250px",
                    backgroundColor: colors.primary,
                    alignSelf: "left",
                  }}>
                  Envoyer
                </Button>
              </Group>
            </form>
          </Box>
        ) : (
          <Box className={classes.right}>
            <PaymentCard reqId={reqId} />
          </Box>
        )}
      </Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />
    </>
  );
};
export default FormForSell;
