import { createStyles, rem } from "@mantine/core";
import { colors } from "../configs";

const formForCheckingStyles = createStyles((theme) => ({
  wrapper: {
    padding: "5px 15%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: colors.primaryLight,

    [theme.fn.largerThan("xl")]: { padding: "5px  15%" },
    [theme.fn.smallerThan("xl")]: { padding: "5px  10%" },
    [theme.fn.smallerThan("lg")]: { padding: "5px  10%" },
    [theme.fn.smallerThan("md")]: { padding: "5px  7%" },
    [theme.fn.smallerThan("sm")]: { padding: "5px  7%" },
    [theme.fn.smallerThan("xs")]: {
      padding: "5px 2%",
    },
    overflow: "hidden",
    gap: 5,
  },

  left: {
    marginTop: "10px",
    flex: 2.5,
    //   backgroundColor: colors.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",

    [theme.fn.smallerThan("lg")]: {
      flex: 2,
    },
    [theme.fn.smallerThan("md")]: {
      flex: 2,
    },
    [theme.fn.smallerThan("sm")]: {
      flex: 0,
      display: "none",
    },
    [theme.fn.smallerThan("xs")]: {
      flex: 0,
      display: "none",
    },
  },
  right: {
    marginTop: "10px",

    flex: 4,
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    // backgroundColor: "teal",
    padding: "10px",
  },
  descBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "sapce-between",
    alignItems: "center",
    maxWidth: "250px",
    maxHeight: "250px",
    padding: "10px",
  },
  descIcon: {},
  descTitle: { fontSize: "18px", fontWeight: "bold" },
  descText: { fontSize: "12px", textAlign: "center" },

  inputs: {
    margin: "5px 0",
    label: {
      fontSize: "14px",
      color: "white",
    },
    description: { color: colors.secondary },
  },
}));
export default formForCheckingStyles;
