import {
  createStyles,
  Text,
  SimpleGrid,
  Container,
  rem,
  Box,
} from "@mantine/core";
import { IconTruck, IconCertificate, IconCoin } from "@tabler/icons-react";
import React from "react";
import HowItWorksFeature from "../../../components/HowItWorks/HowItWorksFeature";
import { colors } from "../../../configs";

const useStyles = createStyles((theme) => ({
  feature: {
    position: "relative",
    paddingTop: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
  },

  overlay: {
    position: "absolute",
    height: rem(100),
    width: rem(160),
    top: 0,
    left: 0,
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    zIndex: 1,
  },

  content: {
    position: "relative",
    zIndex: 2,
  },

  icon: {
    color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
      .color,
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: React.FC<any>;
  title: string;
  description: string;
}

function Feature({
  icon: Icon,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.feature, className)} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <Icon size={rem(38)} className={classes.icon} stroke={1.5} />
        <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.title}>
          {title}
        </Text>
        <Text c="dimmed" fz="sm">
          {description}
        </Text>
      </div>
    </div>
  );
}

const mockdata = [
  {
    icon: IconTruck,
    title: "Vous remplissez notre formulaire",
    description:
      "Un formulaire descriptif du type de bien et de son emplacement",
  },
  {
    icon: IconCertificate,
    title: "Visite du bien",
    description:
      "Slakoth’s heart beats just once a minute. Whatever happens, it is content to loaf around motionless.",
  },
  {
    icon: IconCoin,
    title: "Signature du contrat de vente",
    description:
      "Thought to have gone extinct, Relicanth was given a name that is a variation of the name of the person who discovered.",
  },
  {
    icon: IconCoin,
    title: "Acte de vente",
    description:
      "Dès que nous avons un client nous vous contactons pour l'acte de vente devant un notaire partenaire",
  },
];

const SellHiw = () => {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);

  return (
    <Container mt={30} mb={30} size="lg">
      <Box sx={{ alignSelf: "center" }}>
        <Text
          sx={{
            color: colors.primary,
            fontWeight: "bold",
            fontSize: "36px",
            padding: "5px",
            textAlign: "center",
          }}>
          Comment ça marche ?
        </Text>
      </Box>
      <SimpleGrid
        cols={4}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        spacing={50}>
        {mockdata.map((item, iIdx) => (
          <HowItWorksFeature
            {...item}
            key={iIdx}
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        ))}
      </SimpleGrid>
    </Container>
  );
};
export default SellHiw;
