import { createStyles } from "@mantine/core";
import { colors } from "../../configs";
const headerBarStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: colors.primary,
    gap: 15,
    height: "40px",

    [theme.fn.largerThan("xl")]: {
      padding: "10px 9%",
      justifyContent: "flex-start",
    },
    [theme.fn.smallerThan("xl")]: {
      padding: "10px 8%",
      justifyContent: "flex-start",
    },
    [theme.fn.smallerThan("lg")]: {
      padding: "10px 7%",
      justifyContent: "flex-start",
    },
    [theme.fn.smallerThan("md")]: {
      padding: "10px 5%",
      justifyContent: "space-between",
    },
    [theme.fn.smallerThan("sm")]: {
      padding: "10px 4%",
      justifyContent: "space-between",
      height: "25px",
    },
    [theme.fn.smallerThan("xs")]: {
      padding: "10px 2%",
      justifyContent: "space-between",
      height: "25px",
    },
  },

  icnLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "white",
    textDecoration: "none",

    [theme.fn.smallerThan("md")]: { justifyContent: "center" },
    [theme.fn.smallerThan("sm")]: { justifyContent: "center" },
    [theme.fn.smallerThan("xs")]: { justifyContent: "center" },
  },
  icnText: {
    color: "white",
    [theme.fn.smallerThan("md")]: { display: "none" },
    [theme.fn.smallerThan("sm")]: { display: "none" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
}));

export default headerBarStyles;
