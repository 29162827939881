import React, { useState } from "react";
import {
  Group,
  Button,
  Box,
  Paper,
  Text,
  Radio,
  SimpleGrid,
  TextInput,
  Title,
  Textarea,
  LoadingOverlay,
  Select,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { addDoc, collection } from "firebase/firestore";

import Navbar from "../components/Navbar/Navbar";
import { db } from "../firebase-config";
import { IconBulbFilled, IconChecks, IconStar } from "@tabler/icons-react";
import cities from "../shared/citiies";
import communes from "../shared/communes";
import useFirebaseAuth from "../context/authuser/useFirebaseAuth";
import UploaDocument from "../components/UploadDocument/UploaDocument";
import docs from "../shared/docs";
import { colors } from "../configs";
import formForCheckingStyles from "./formForCheckingStyles";

const FormForAcd = () => {
  const { loggedUser } = useFirebaseAuth();
  const { classes } = formForCheckingStyles();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [reqId, setReqId] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      //  documentType: "",
      type: "",
      ville: "",
      commune: "",
      //  value: "",
      //  surfaceTotal: "",
      isAcdStarted: "",
      description: "",
    },
    validate: {
      //  documentType: (value) => value.trim().length < 6,
      type: (value) => (value ? null : "Veuillez saisir votre nom"),
      ville: (value) => value.trim().length < 4,
      //  commune: (value) => value.trim().length < 4,
      //  started: (value) => value.trim().length < 4,

      //  surfaceTotal: (value) => value.trim().length < 2,
      description: (value) => value.trim().length < 4,
    },
  });

  const sendInfo = async (reqData: any) => {
    setLoading(true);
    reqData.requesterId = loggedUser;
    reqData.country = "CI";
    reqData.allowedBailifId = "ADMIN"; //should be replace by an admin's id
    reqData.model = "acd";
    reqData.status = 0;
    reqData.createdAt = new Date().getTime();
    reqData.docsList = [];
    console.log("====================================");
    console.warn("THE INFORMATION DATA: ", reqData);
    console.log("====================================");

    await addDoc(collection(db, "requests"), reqData)
      .then((docRef: any) => {
        console.log("Document written with ID: ", docRef.id);
        setReqId(docRef.id);
        setSuccessMsg(
          "Votre demande a été enregistrée, nos experts vous recontacterons dans de plus brefs délais"
        );
        setLoading(false);
      })
      .catch((saveError: any) => {
        setReqId(null);
        console.error("ERROR SAVING YOUR REQUEST: ", saveError);
        setErrorMsg(
          "Désolé nous n'avons pas pu enregistrer votre demande réessayer si l'erruer persiste vérifier votre connexion ou réactualisez la page avant de recommencer."
        );
        setLoading(false);
      });
  };

  const styles = {
    color: "white",
    inputs: {
      margin: "5px 0",
      label: {
        fontSize: "14px",
        color: "white",
      },
      description: { color: colors.secondary },
    },
  };
  return (
    <>
      <Navbar />
      <Box className={classes.wrapper}>
        <Box className={classes.left} sx={{ display: reqId ? "none" : "flex" }}>
          <Paper className={classes.descBox}>
            <IconBulbFilled
              size={50}
              className={classes.descIcon}
              style={{ color: "pink" }}
            />
            <Text className={classes.descTitle}>Précis et clair.</Text>
            <Text className={classes.descText}>
              Des données claires et concises facilitent et accélèrent le
              traitement de votre demande.
            </Text>
          </Paper>
          <Paper className={classes.descBox}>
            <IconChecks
              size={50}
              className={classes.descIcon}
              style={{ color: "green" }}
            />
            <Text className={classes.descTitle}> Correctes</Text>
            <Text className={classes.descText}>
              Des informations correctes nous aident à mieux évaluer avec
              exactitude la valeur de votre bien.
            </Text>
          </Paper>
          <Paper className={classes.descBox}>
            <IconStar
              size={50}
              className={classes.descIcon}
              style={{ color: "red" }}
            />
            <Text className={classes.descTitle}>Description</Text>
            <Text className={classes.descText}>
              Une bonne description peut renforcer la précisions sur les
              informations saisies.
            </Text>
          </Paper>
        </Box>
        {reqId == null && reqId != "" ? (
          <Box className={classes.right}>
            <Title
              order={2}
              size="h2"
              weight={900}
              align="center"
              color="yellow">
              Obtenez votre ACD en Côte d'Ivoire en toute sérénité -
              Contactez-nous pour une assistance personnalisée{" "}
            </Title>
            <form onSubmit={form.onSubmit((data) => sendInfo(data))}>
              <Radio.Group
                mb="lg"
                styles={(theme) => ({
                  label: {
                    fontSize: "14px",
                    color: "white",
                  },
                  description: {
                    color: colors.secondary,
                  },
                })}
                label="Catégorie du bien"
                //  description="Faites un choix"
                name="type"
                withAsterisk
                {...form.getInputProps("type", { type: "checkbox" })}>
                <Group mt="xs">
                  <Radio
                    value="TYPE_1"
                    label="Terrain"
                    styles={(theme) => ({
                      label: {
                        fontSize: "14px",
                        color: "white",
                      },
                    })}
                  />
                  <Radio
                    value="TYPE_2"
                    label="Bien immobilier"
                    styles={(theme) => ({
                      label: {
                        fontSize: "14px",
                        color: "white",
                      },
                    })}
                  />
                </Group>
                <Radio.Group
                  mt="lg"
                  styles={(theme) => ({
                    label: {
                      fontSize: "14px",
                      color: "white",
                    },
                    description: {
                      color: colors.secondary,
                    },
                  })}
                  label="Avez vous déja entamé la procédure d'acquisition de votre ACD?"
                  //  description="Si le bien n'est pas à Abidjan ni dans le grand abidjan coché interrieur"
                  withAsterisk
                  name="isAcdStarted"
                  {...form.getInputProps("isAcdStarted", { type: "checkbox" })}>
                  <Group mt="xs">
                    <Radio
                      value="Oui"
                      label="Oui"
                      styles={(theme) => ({
                        label: {
                          fontSize: "14px",
                          color: "white",
                        },
                      })}
                    />
                    <Radio
                      value="Non"
                      label="Non"
                      styles={(theme) => ({
                        label: {
                          fontSize: "14px",
                          color: "white",
                        },
                      })}
                    />
                  </Group>
                </Radio.Group>
              </Radio.Group>

              <SimpleGrid
                cols={2}
                mt="md"
                breakpoints={[
                  { maxWidth: "sm", cols: 2 },
                  { maxWidth: "xs", cols: 1 },
                ]}>
                <Select
                  withAsterisk
                  name="ville"
                  searchable
                  label="Ville"
                  description="La ville où est situé le bien"
                  placeholder="choisir"
                  nothingFound="Aucune correspondance"
                  data={cities}
                  clearable
                  {...form.getInputProps("ville")}
                  styles={(theme) => styles.inputs}
                />
                <Select
                  disabled={form.values.ville !== "abidjan"}
                  name="commune"
                  searchable
                  label="Commune"
                  description="Uniquement si le bien est à Abidjan"
                  placeholder="choisir"
                  nothingFound="Aucune correspondance"
                  data={communes}
                  clearable
                  {...form.getInputProps("commune")}
                  styles={(theme) => styles.inputs}
                />
              </SimpleGrid>

              <Textarea
                withAsterisk
                mt="md"
                label="Description du bien"
                placeholder="Décrivez en brièvement le bien mis en vente"
                maxRows={10}
                minRows={5}
                autosize
                name="description"
                variant="filled"
                {...form.getInputProps("description")}
                styles={(theme) => styles.inputs}
              />
              <Group
                position="left"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}>
                <Text style={{ color: "green" }}>{successMsg}</Text>
                <Text style={{ color: "red" }}>{errorMsg}</Text>
                <Button
                  disabled={loading}
                  type="submit"
                  size="md"
                  sx={{
                    minWidth: "250px",
                    backgroundColor: colors.primary,
                    alignSelf: "left",
                  }}>
                  Envoyer
                </Button>
              </Group>
            </form>
          </Box>
        ) : (
          <Box className={classes.right}>
            <UploaDocument modelId={reqId} docType={null} />
          </Box>
        )}
      </Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />
    </>
  );
};
export default FormForAcd;
