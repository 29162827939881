import React from "react";

import { Box, Text } from "@mantine/core";
import { colors } from "../../configs";
import ServicesExplanationStyles from "./ServicesExplanationStyles";

const ServicesExplanation = () => {
  const { classes } = ServicesExplanationStyles();
  return (
    <Box className={classes.wrapper}>
      <Text className={classes.title}>
        Transparence Immobilière, gardien de votre tranquilité d'esprtit!
      </Text>
      <Text className={classes.description}>
        Notre mission est de garantir l'intégrité dans le secteur immobilier et
        foncier en Côte d'ivoire. <br />
        Une équipe d'experts dévoués s'assurent que chaque transaction est
        conforme, l'égale et sans risque.
      </Text>
    </Box>
  );
};

export default ServicesExplanation;
