import React from "react";

import { Box, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import Facebook from "../../assets/icons/facebook.png";
import LinkedIn from "../../assets/icons/linkedin.png";
import Twitter from "../../assets/icons/twitter.png";
import Youtube from "../../assets/icons/youtube.png";
import footerStyles from "./footerStyles";
import Contact from "../Contact/Contact";
import {
  IconBrandWhatsapp,
  IconMail,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react";

const Footer = () => {
  const { classes } = footerStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.mainBox}>
        <Box
          sx={{
            flex: 1,
          }}>
          <Text className={classes.commonTitle}>
            <Text>Transparence</Text>
            <Text>Immoblière</Text>
          </Text>
          <Text className={classes.commonText}>
            TransparenceImmoblière est une entreprise digitale spécialisée dans
            l’assistance juridique et administrative. Nous sommes le numéro 1 en
            Afrique, avec des représentations dans plusieurs pays.
          </Text>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}>
          <Text className={classes.commonTitle}>Liens Utiles</Text>
          <Link to="/" style={{ color: "white" }}>
            Partenaires & Affiliation
          </Link>
          <Link to="/" style={{ color: "white" }}>
            Conditions générales d'utilisation
          </Link>
        </Box>
        <Box
          sx={{
            flex: 1,
            alignContent: "center",
          }}>
          <Text
            className={classes.commonTitle}
            sx={{
              marginBottom: "5px",
            }}>
            Contact
          </Text>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <Box className={classes.contactItemBox}>
              <IconMapPin />
              <Text className={classes.commonText}>
                : Abidjan-Cocody II plateaux 7è Tranche lot 2905
              </Text>
            </Box>
            <Box className={classes.contactItemBox}>
              <IconMail />
              <Text className={classes.commonText}>
                : contact@transparenceimmobilière.com
              </Text>
            </Box>
            <Box className={classes.contactItemBox}>
              <IconBrandWhatsapp />
              <Text className={classes.commonText}>: +225 07 77 14 42 29</Text>
            </Box>
            <Box className={classes.contactItemBox}>
              <IconPhone />
              <Text className={classes.commonText}>: +225 07 77 14 42 29</Text>
            </Box>
          </Box>

          <Contact />
        </Box>
      </Box>
      <hr />
      <Box className={classes.socialBox}>
        <Box className={classes.socialIconWrapper}>
          <img src={Facebook} alt="Facebook" className={classes.socialIcon} />
          <img src={LinkedIn} alt="linkedIn" className={classes.socialIcon} />
          <img src={Twitter} alt="Twitter" className={classes.socialIcon} />
          <img src={Youtube} alt="Youtube" className={classes.socialIcon} />
        </Box>
        <Box>
          <Text className={classes.siteName}>Transparenceimmobiliere.com</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
