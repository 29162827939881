import React from "react";
import { Accordion, Box, rem, Text } from "@mantine/core";
import { colors } from "../../../configs";
import faqStyles from "./faqStyles";

const CheckFaq = () => {
  const { classes } = faqStyles();
  return (
    <Box className={classes.wrapper}>
      <Text className={classes.faqTitle}>
        Formalité ACD : Questions fréquentes
      </Text>
      <Box sx={{ width: "100%", padding: "0 10%", display: "flex" }}>
        <Accordion
          variant="contained"
          sx={{ flex: 1, width: "100%", color: colors.primary }}
          styles={{
            item: {
              // styles added to all items
              backgroundColor: "#e2e9e8",
              border: `${rem(2)} solid  #c6d2d2`,
              // styles added to expanded item
              "&[data-active]": {
                backgroundColor: "#fff",
              },
              fontSize: "15px",
            },
            label: {
              color: colors.primary,
              fontWeight: "bold",
              fontSize: "15px",
            },
            chevron: {
              // styles added to chevron when it should rotate
              "&[data-rotate]": {
                transform: "rotate(-90deg)",
              },
            },
          }}>
          <Accordion.Item value="customization" color={colors.primary}>
            <Accordion.Control
              sx={{
                color: colors.primary,
                fontWeight: "bold",
              }}>
              Que représente l’acte de cession ou l’attestation de propriété
              coutumière ?
            </Accordion.Control>
            <Accordion.Panel>
              L'attestation de propriété coutumière est un papier délivré par le
              chef du village qui permet de prouver qu’un individu est le
              propriétaire d'une parcelle de terre. Ce document est très
              important car il donne la reconnaissance légale et la pleine
              propriété d'un terrain. L'attestation de propriété coutumière est
              signée par le chef du village, ce qui lui donne une valeur légale
              et officielle en tant que preuve de la propriété foncière. L'acte
              de cession, quant à lui, est un document qui permet à un
              propriétaire de vendre son terrain à un nouvel acquéreur.
              Lorsqu'un propriétaire cède sa propriété à quelqu'un d'autre, la
              chefferie met à jour ses registres et remplace le nom de l'ancien
              propriétaire par celui du nouvel acquéreur. Le nouvel acquéreur
              recevra alors une nouvelle attestation de cession qui prouvera
              qu'il est le nouveau propriétaire de la parcelle. Son nom sera
              inscrit en dessous de celui de l'ancien propriétaire dans le guide
              du village.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="flexibility">
            <Accordion.Control>
              Qui monte le dossier technique de bornage ?
            </Accordion.Control>
            <Accordion.Panel>
              Le dossier technique de bornage est préparé par un géomètre expert
              qui a été agréé par le Ministère de la Construction du Logement et
              de l'Urbanisme. Ce dossier est composé d'un rapport du géomètre,
              de coordonnées tirées du cadastre (coordonnées des sommets de la
              parcelle utilisée ou de la Polygonation), du dossier de
              morcellement, ainsi que d'un plan A3 avec des informations telles
              que les coordonnées des bornes, des angles et des distances du
              terrain. Le géomètre expert, en question, est enregistré auprès de
              l'ordre des géomètres experts du pays.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="focus-ring">
            <Accordion.Control>
              Qu’est-ce qu’un acte compulsoire ?
            </Accordion.Control>
            <Accordion.Panel>
              Le compulsoire est un document juridique officiel rédigé par un
              Commissaire de Justice, également connu sous le nom d'Huissier de
              justice. Ce document a deux fonctions légales importantes : Tout
              d'abord, le compulsoire permet de faire un constat. Cela signifie
              que le commissaire de justice vérifie si le propriétaire terrien
              ou le nouvel acquéreur a correctement inscrit son nom dans le
              guide du village. Si ce n'est pas le cas, le commissaire de
              justice s'assure que le nom est bien inscrit dans le guide
              approprié. Ensuite, le compulsoire permet au commissaire de
              justice de signifier au propriétaire ou le nouvel acquéreur
              d'inscrire son nom dans les registres correspondants du Ministère
              de la Construction du Logement et de l'Urbanisme. De cette façon,
              le compulsoire garantit que le nom du propriétaire ou du nouvel
              acquéreur est correctement inscrit à la fois dans le guide du
              village et dans le registre officiel du Ministère de la
              Construction du Logement et de l'Urbanisme.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="focus-ring">
            <Accordion.Control>
              Qu’est-ce qu’un titre foncier ?
            </Accordion.Control>
            <Accordion.Panel>
              Le titre foncier est le premier document officiel reconnu par
              l'Etat sur une parcelle. Le titre foncier se manifeste par
              l'octroi d'un numéro de Titre Foncier (TF) d'une circonscription
              bien déterminée. À partir de sa réception, les impôts vous
              enregistrent dans leurs bases de données pour des éventuels
              paiements d'impôt foncier. Le titre foncier était avant 2013, le
              document par excellence qui conférait à un propriétaire terrien la
              pleine propriété. De 2013 à ce jour, l'Arrêté de Concession
              Définitive (ACD) est le titre de pleine propriété définitive. Si
              vous êtes détenteur d’un terrain avec un Titre Foncier vous devez
              faire une mise à jour afin d’obtenir votre ACD.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="focus-ring">
            <Accordion.Control>
              Quelles sont les pièces à fournir pour une demande d’ACD avec une
              attestation villageoise ?
            </Accordion.Control>
            <Accordion.Panel>
              - L'attestation villageoise ou l'attestation d'attribution
              originale + (4 photocopies) - L'attestation de cession originale +
              (4 photocopies) - Quatre (4) photocopies de la pièce d'identité ou
              du passeport (en cours de validité) du demandeur - Quatre (4)
              photocopies de l'extrait de naissance (peu importe la date
              d'établissement de l'extrait de naissance) - Le dossier technique
              original + 21 copies du calque NB : Assurez-vous d’avoir terminé
              obligatoirement l’étape du compulsoire avant d’introduire votre
              demande d’ACD.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="focus-ring">
            <Accordion.Control>
              Combien de temps met la procédure de délivrance de l’ACD ?
            </Accordion.Control>
            <Accordion.Panel>
              Officiellement 6 mois mais dans la pratique, il faut prévoir un
              délai un peu plus long.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
    </Box>
  );
};

export default CheckFaq;
