import React from "react";
import Footer from "../../components/Footer/Footer";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import IntroBanner from "../../components/IntroBanner/IntroBanner";
import Navbar from "../../components/Navbar/Navbar";
import SellExpert from "./SellExpert/SellExpert";
import SellHero from "./SellHero/SellHero";
import SellHiw from "./SellHiw/SellHiw";
import SellWus from "./SellWus/SellWus";

const Vente = () => {
  return (
    <>
      <HeaderBar />
      <Navbar />
      <SellHero />
      <SellHiw />
      <SellWus />
      <IntroBanner />
      <SellExpert />
      <Footer />
    </>
  );
};

export default Vente;
