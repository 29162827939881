import React from "react";
import Footer from "../../components/Footer/Footer";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import IntroBanner from "../../components/IntroBanner/IntroBanner";
import Navbar from "../../components/Navbar/Navbar";
import CheckExpert from "./CheckExpert/CheckExpert";
import CheckFaq from "./CheckFaq/CheckFaq";
import CheckHero from "./CheckHero/CheckHero";
import CheckHiw from "./CheckHiw/CheckHiw";
import CheckWus from "./CheckWus/CheckWus";

const Verification = () => {
  return (
    <>
      <HeaderBar />
      <Navbar />
      <CheckHero />
      <IntroBanner />
      <CheckHiw />
      <CheckWus />
      <CheckFaq />
      <CheckExpert />
      <Footer />
    </>
  );
};

export default Verification;
