import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Services from "../../components/Services/Services";
import Works from "../../components/Works/Works";
import Portfolio from "../../components/Portfolio/Porfolio";
import Testimonial from "../../components/Testimonial/Testimonial";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import IntroBanner from "../../components/IntroBanner/IntroBanner";
import ServicesExplanation from "../../components/ServicesExplanation/ServicesExplanation";
import HomeHero from "./HomeHero/HomeHero";
import HomeAbout from "./HomeAbout/HomeAbout";
import { Box } from "@mantine/core";
import ScrollToHashElement from "../../components/ScrollToHashElement/ScrollToHashElement";

const Home = () => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <ScrollToHashElement />

      <Navbar />
      <HomeHero />
      <ServicesExplanation />
      <Services />
      <IntroBanner />

      <HomeAbout />
      <Works />
      <Portfolio />
      <Testimonial />
      <Footer />
    </Box>
  );
};

export default Home;
