const sizes = {
  radius: "20px",
  dropDownWidth: "100%",
  dropDownHeight: "250px",
  dropDownItemSize: "14px",

  //page padding
  supXl_pd: "",
  xl_pd: "",
  lg_pd: "",
  md_pd: "",
  sm_pd: "",
  xs_pd: "",

  //section padding
  supXl_section_pd: "3%  8%",
  xl_section_pd: "3%  8%",
  lg_section_pd: "3%  7%",
  md_section_pd: "2%  5%",
  sm_section_pd: "2%  4%",
  xs_section_pd: "1% 2%",

  //card padding
  supXl_card_pd: "10px 15px",
  xl_card_pd: "7px 15px",
  lg_card_pd: "7px 15px",
  md_card_pd: "7px 15px",
  sm_card_pd: "15px 5px",
  xs_card_pd: "15px 5px",

  //title size
  supXl_title: "36px",
  xl_title: "36px",
  lg_title: "36px",
  md_title: "36px",
  sm_title: "33px",
  xs_title: "27px",

  //subtitle size
  supXl_subTitle: "",
  xl_subTitle: "",
  lg_subTitle: "",
  md_subTitle: "",
  sm_subTitle: "",
  xs_subTitle: "",

  //description size
  supXl_desc: "27px",
  xl_desc: "24px",
  lg_desc: "24px",
  md_desc: "24px",
  sm_desc: "21px",
  xs_desc: "16px",
};
export default sizes;
