import React from "react";
import { Title, Text, Box } from "@mantine/core";
import {
  IconSearch,
  IconRoad,
  //  IconBuildingCommunity,
  IconHomeDollar,
} from "@tabler/icons-react";
import heroStyles from "./heroStyles";

import FloatinDiv from "../../../components/FloatingDiv/FloatingDiv";

import boy2 from "../../../assets/img/boy.png";
import { colors } from "../../../configs";

const HomeHero = () => {
  const { classes } = heroStyles();

  return (
    <div className={classes.wrapper}>
      <Box className={classes.leftBox}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Title className={classes.title}>
            Un terrain,
            <br />
            un propriétaire!
          </Title>
          <Text className={classes.description}>
            Bienvenue chez Transparence immobilière, votre partenaire de
            confiance pour des transactions immobilières en toute sécurité en
            Côte d'ivoire.
          </Text>
        </Box>
      </Box>

      <Box className={classes.centerBox}>
        <FloatinDiv
          img={
            <IconSearch color={colors.primary} size="40" fontWeight="bolder" />
          }
          text1="Vérification avant"
          text2="achat de terrain"
          link="verification-preacquisition"
        />

        <FloatinDiv
          img={
            <IconRoad color={colors.primary} size="40" fontWeight="bolder" />
          }
          text1="Accompagnement"
          text2="démarches d'ACD"
          link="demarches-acd"
        />

        <FloatinDiv
          img={
            <IconHomeDollar
              color={colors.primary}
              size="40"
              fontWeight="bolder"
            />
          }
          text1="Achat et vente de "
          text2="térrains et maisons"
          link="vente"
        />
      </Box>
      <Box className={classes.rightBox}>
        <Box
          style={{
            display: "flex",
            backgroundImage: `url(${boy2})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "center",
            width: "100%",
            height: "100%",
            flex: 1,
            //  transform: "scale(0.59)",
          }}></Box>
      </Box>
    </div>
  );
};

export default HomeHero;
