import React, { useState } from "react";
import {
  Text,
  Group,
  Box,
  TextInput,
  Select,
  Button,
  Avatar,
  SimpleGrid,
} from "@mantine/core";
import {
  IconPhoneCall,
  IconAt,
  IconUser,
  IconMapPin,
} from "@tabler/icons-react";
import AVATAR from "../../../../assets/icons/avatar.png";
import { useForm } from "@mantine/form";
import countries from "../../../../shared/countries";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase-config";
import { getCountryLabel } from "../../../../utils/getLabels";
import userCardStyles from "./userCardStyles";

/*
interface UserInfoIconsProps {
  avatar: string;
  name: string;
  title: string;
  phone: string;
  email: string;
}
*/
/*
const UserCard = ({
  avatar,
  name,
  title,
  phone,
  email,
}: UserInfoIconsProps) => {
    */
const UserCard = ({ data }: any) => {
  const form = useForm({
    initialValues: {
      name: data.name,
      firstname: data.firstname,
      email: data.email,
      country: data.country,
      phone: data.phone,
    },
    validate: {
      name: (value) => value.trim().length < 2,
      firstname: (value) => value.trim().length < 2,
      email: (value) => !/^\S+@\S+$/.test(value),
      country: (value) => value.trim().length === 0,
      phone: "",
    },
  });
  const { classes } = userCardStyles();

  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");

  const updateData = async (uData: any) => {
    const userRef = doc(db, "users", data.uid);

    console.log("====================================");
    console.log("UPDATED DATA: ", data);
    console.log("====================================");
    // Set the "capital" field of the city 'DC'
    await updateDoc(userRef, uData);
  };

  return (
    <SimpleGrid
      cols={2}
      breakpoints={[
        { maxWidth: "sm", cols: 1, spacing: "sm" },
        { maxWidth: "xs", cols: 1, spacing: "xs" },
      ]}>
      <Box
        sx={{
          flex: 1.75,
          alignItems: "flex-start",
        }}>
        <Text
          sx={{
            fontWeight: "bold",
            color: "white",
            fontSize: "21px",
            marginBottom: "10px",
          }}>
          Informations personnelles
        </Text>
        <Group
          noWrap
          sx={{
            alignItems: "flex-start",
          }}>
          <Avatar src={AVATAR} size={94} radius="md" />
          <div>
            <Group noWrap spacing={10} mt={3}>
              <IconUser stroke={1.5} size="1rem" className={classes.icon} />

              <Text fz="sm" tt="uppercase" fw={700} c="dimmed">
                {data?.firstname} {data?.name}
              </Text>
            </Group>

            <Group noWrap spacing={10} mt={3}>
              <IconAt stroke={1.5} size="1rem" className={classes.icon} />

              <Text fz="sm" c="dimmed">
                {data?.email}
              </Text>
            </Group>

            <Group noWrap spacing={10} mt={5}>
              <IconPhoneCall
                stroke={1.5}
                size="1rem"
                className={classes.icon}
              />
              <Text fz="sm" c="dimmed">
                <Text fz="sm" c="dimmed">
                  {data?.phone}
                </Text>
              </Text>
            </Group>
            <Group noWrap spacing={10} mt={3}>
              <IconMapPin stroke={1.5} size="1rem" className={classes.icon} />

              <Text fz="sm" c="dimmed">
                <img
                  src={require(`../../../../assets/flags/16x16/${getCountryLabel(
                    data?.country
                  ).value.toLowerCase()}.png`)}
                  alt={`${getCountryLabel(data?.country).value}`}
                />
                {getCountryLabel(data?.country).label}
              </Text>
            </Group>
          </div>
        </Group>
      </Box>
      <form
        style={{
          flex: 1.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
        onSubmit={form.onSubmit((data) => updateData(data))}>
        <Box
          m={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            minWidth: "100%",
          }}>
          <TextInput
            sx={{ flex: 2 }}
            className={classes.inputs}
            name="name"
            label="Nom"
            //  value={name ? name : ""}
            {...form.getInputProps("name")}
            //  onChange={(nameEvt: any) => setName(nameEvt.target.value)}
          />
          <TextInput
            sx={{ flex: 3.5 }}
            className={classes.inputs}
            name="firstname"
            label="Prénoms(s)"
            //  value={firstname ? firstname : ""}
            {...form.getInputProps("firstname")}
            //  onChange={(ftNameEvt: any) => setFirstname(ftNameEvt.target.value)}
          />
        </Box>
        <Box
          m={1}
          sx={{
            minWidth: "100%",
          }}>
          <Select
            className={classes.inputs}
            searchable
            defaultValue={getCountryLabel(data?.country).value}
            label="Pays de résidence"
            placeholder="Cliquez-ici"
            nothingFound="Aucune correspondance"
            data={countries}
            clearable
            {...form.getInputProps("country")}
          />
          {data?.provider === "email" ? (
            <TextInput
              className={classes.inputs}
              name="phone"
              type="tel"
              label="Un numéro de contact"
              //  value={repeatEmail}
              {...form.getInputProps("phone")}
              //  onChange={(emailEvt: any) => setRepeatEmail(emailEvt.target.value)}
            />
          ) : (
            <TextInput
              className={classes.inputs}
              name="email"
              type="email"
              label="Votre email de contact"
              //  value={email}
              {...form.getInputProps("email")}
              //  onChange={(emailEvt: any) => setEmail(emailEvt.target.value)}
            />
          )}
        </Box>
        <Text variant="body2" color="success">
          {successMsg}
        </Text>
        <Text variant="body2" color="error">
          {errorMsg}
        </Text>

        <Button className={classes.confirmButon} type="submit">
          Modifier
        </Button>
      </form>
    </SimpleGrid>
  );
};
export default UserCard;
