import React from "react";
import { Box, Text, Button } from "@mantine/core";
import ACD_COVER from "../../../assets/images/acd-img.webp";
import heroStyles from "./heroStyles";

const CheckHero = () => {
  const { classes } = heroStyles();
  //  const transition = { duration: 2, type: "spring" };

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.sndBox}>
          <Box className={classes.sbDescBox}>
            <Text className={classes.fbTitle}>
              <span className={classes.coloredText}>Achetez</span>votre
              <span className={classes.coloredText}>terrain</span>
              en toute
              <br className={classes.fbbr} />
              <span className={classes.coloredText}>sécurité</span>
            </Text>
            <Text className={classes.sbdbText}>
              Sécursiez l'achat de votre terrain en nous confiant toute la
              procédure de vérification.
            </Text>
            <Button
              component="a"
              rel="noopener noreferrer"
              href="/formulaire-preacquisition"
              className={classes.sbdbBtn}>
              Entamer les démarches
            </Button>
          </Box>
          <Box className={classes.sbImgBox}>
            <img
              className={classes.sbibImg}
              src={ACD_COVER}
              alt="Obetenez votre ACD en toute sécurité"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CheckHero;
