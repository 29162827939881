import React, { useState } from "react";
import { Button, Drawer, Text } from "@mantine/core";
import { IconMail } from "@tabler/icons-react";
import { colors } from "../../configs";
import { useDisclosure } from "@mantine/hooks";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div
      style={{
        paddingTop: "15px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        padding: "5px",
        borderRadius: "15px",
      }}>
      <Text
        sx={{
          color: colors.secondary,
          fontSize: "21px",
          fontWeight: "bold",
          textAlign: "center",
        }}>
        Ou
      </Text>
      <Button
        onClick={open}
        sx={{ padding: "10px", minHeight: "50px" }}
        variant="gradient"
        gradient={{ from: colors.secondary, to: colors.primaryLight }}>
        <IconMail size="50" /> Cliquer ici pour nous envoyer un message.
      </Button>
      <Drawer opened={opened} onClose={close}>
        <ContactForm />
      </Drawer>
    </div>
  );
};

export default Contact;
