const communes = [
  {
    label: "Abobo",
    value: "abobo",
    icon: "",
  },
  {
    label: "Adjamé",
    value: "adjamé",
    icon: "",
  },
  {
    label: "Anyama",
    value: "anyama",
    icon: "",
  },
  {
    label: "Attécoubé",
    value: "attécoubé",
    icon: "",
  },
  {
    label: "Bingerville",
    value: "bingerville",
    icon: "",
  },
  {
    label: "Cocody",
    value: "cocody",
    icon: "",
  },
  {
    label: "Koumassi",
    value: "koumassi",
    icon: "",
  },
  {
    label: "Marcory",
    value: "marcory",
    icon: "",
  },
  {
    label: "Plateau",
    value: "plateau",
    icon: "",
  },
  {
    label: "Port bouët",
    value: "port_bouet",
    icon: "",
  },
  {
    label: "Treichville",
    value: "treichville",
    icon: "",
  },
  {
    label: "Songon",
    value: "songon",
    icon: "",
  },
  {
    label: "Yopougon",
    value: "yopougon",
    icon: "",
  },
];

export default communes;
