import React from "react";

import "./FloatingDiv.css";
import { Box, ThemeIcon, Text } from "@mantine/core";
import { colors, sizes } from "../../configs";

import { createStyles } from "@mantine/core";

const floatingStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.primary,
    borderRadius: "18px",
    textDecoration: "none",
    [theme.fn.largerThan("xl")]: {
      padding: sizes.supXl_card_pd,

      gap: 15,
    },
    [theme.fn.smallerThan("xl")]: {
      padding: sizes.xl_card_pd,

      gap: 15,
    },
    [theme.fn.smallerThan("lg")]: {
      padding: sizes.lg_card_pd,

      gap: 15,
    },
    [theme.fn.smallerThan("md")]: {
      padding: sizes.md_card_pd,

      gap: 15,
    },
    [theme.fn.smallerThan("sm")]: {
      minWidth: "300px",
      maxWidth: "45%",

      padding: sizes.sm_card_pd,
      flexDirection: "column",
      textAlign: "center",
      gap: 5,
    },
    [theme.fn.smallerThan("xs")]: {
      minWidth: "150px",
      width: "45%",
      padding: sizes.xs_card_pd,
      flexDirection: "column",
      textAlign: "center",
      gap: 5,
    },
  },
  itemThemIcon: {
    minWidth: 50,
    minHeight: 50,
    //  [theme.fn.largerThan("xl")]: { fontSize: theme.fontSizes.xl },
    //  [theme.fn.smallerThan("xl")]: { fontSize: theme.fontSizes.lg },
    [theme.fn.smallerThan("lg")]: { minWidth: 45, minHeight: 45 },
    [theme.fn.smallerThan("md")]: { minWidth: 40, minHeight: 40 },
    [theme.fn.smallerThan("sm")]: { minWidth: 35, minHeight: 35 },
    [theme.fn.smallerThan("xs")]: { minWidth: 30, minHeight: 30 },
  },
  itemTitle: {
    color: "white",
    [theme.fn.largerThan("xl")]: { fontSize: theme.fontSizes.lg },
    [theme.fn.smallerThan("xl")]: { fontSize: theme.fontSizes.lg },
    [theme.fn.smallerThan("lg")]: { fontSize: theme.fontSizes.lg },
    [theme.fn.smallerThan("md")]: { fontSize: theme.fontSizes.md },
    [theme.fn.smallerThan("sm")]: { fontSize: theme.fontSizes.sm },
    [theme.fn.smallerThan("xs")]: { fontSize: theme.fontSizes.xs },
  },
}));
const FloatinDiv = ({ img, text1, text2, link }: any) => {
  const { classes } = floatingStyles();

  return (
    <Box component="a" href={`/${link}`} className={classes.item}>
      <ThemeIcon
        radius="md"
        variant="gradient"
        gradient={{
          deg: 133,
          from: colors.secondary,
          to: colors.secondary,
        }}
        className={classes.itemThemIcon}>
        {img}
      </ThemeIcon>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}>
        <Text className={classes.itemTitle}>{text1}</Text>
        <Text className={classes.itemTitle}>{text2}</Text>
      </Box>
    </Box>
  );
};

export default FloatinDiv;
