import React from "react";

import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { colors } from "../../configs";

const Navbutton = ({ title, link, icon, bgColor }: any) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(`/${link}`)}
      className="button n-button"
      variant="gradient"
      gradient={{ from: colors.primary, to: colors.primaryLight, deg: 180 }}
      leftIcon={icon}>
      {title}
    </Button>
  );
};

export default Navbutton;
