import { createStyles } from "@mantine/core";
import { colors } from "../../configs";
const navbarStyles = createStyles((theme) => ({
  wrapper: {
    padding: "5px 8%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.fn.largerThan("xl")]: { padding: "5px 8%" },
    [theme.fn.smallerThan("xl")]: { padding: "5px 7%" },
    [theme.fn.smallerThan("lg")]: { padding: "5px 6%" },
    [theme.fn.smallerThan("md")]: { padding: "5px 5%" },
    [theme.fn.smallerThan("sm")]: { padding: "5px 4%" },
    [theme.fn.smallerThan("xs")]: { padding: "5px 2%" },
  },

  logo: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "5px",

    [theme.fn.smallerThan("sm")]: { width: "40px", height: "40px" },
    [theme.fn.smallerThan("xs")]: { width: "35px", height: "35px" },
  },
  siteTitleBox: {},
  siteTitle: {
    //  fontSize: 36,
    color: colors.primary,
    lineHeight: "1.2",
    fontWeight: "bold",
    [theme.fn.largerThan("xl")]: { fontSize: "24px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "21px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "18px" },
    [theme.fn.smallerThan("md")]: { fontSize: "15px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "12px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "11px" },
    /*
    [theme.fn.smallerThan("xs")]:
      {
        fontsize: 5,
    },
    */
  },
  leftNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "none",
  },

  centerNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    /*
    [theme.fn.largerThan("xl")]: { display: "flex" },
    [theme.fn.smallerThan("xl")]: { display: "flex" },
    [theme.fn.smallerThan("lg")]: { display: "flex" },
    */
    [theme.fn.smallerThan("md")]: { display: "none" },
    [theme.fn.smallerThan("sm")]: { display: "none" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
  mobileCenterNav: {
    display: "none",
    /*
    [theme.fn.largerThan("xl")]: {
      display: "none",
    },
    [theme.fn.smallerThan("xl")]: { display: "none" },
    [theme.fn.smallerThan("lg")]: { display: "none" },
    */
    [theme.fn.smallerThan("md")]: { display: "flex" },
    [theme.fn.smallerThan("sm")]: { display: "flex" },
    [theme.fn.smallerThan("xs")]: { display: "flex" },
  },
  rightNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 1,
    /*
    [theme.fn.largerThan("xl")]: { display: "flex" },
    [theme.fn.smallerThan("xl")]: { display: "flex" },
    [theme.fn.smallerThan("lg")]: { display: "flex" },
*/
    [theme.fn.smallerThan("md")]: { display: "none" },
    [theme.fn.smallerThan("sm")]: { display: "none" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
  mobileRightNav: {
    display: "none",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    gap: 0,
    /*
    [theme.fn.largerThan("xl")]: {
      display: "none",
    },
    
    [theme.fn.smallerThan("xl")]: { display: "none" },
    [theme.fn.smallerThan("lg")]: { display: "none" },
    */
    [theme.fn.smallerThan("md")]: { display: "flex" },
    [theme.fn.smallerThan("sm")]: { display: "flex" },
    [theme.fn.smallerThan("xs")]: { display: "flex" },
  },
}));

export default navbarStyles;
