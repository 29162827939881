const docs = [
  {
    label: "Attestation villageoise",
    value: "ATT_VIL",
    icon: "",
  },
  {
    label: "Lettre d'attribution",
    value: "LET_ATT",
    icon: "",
  },
  {
    label: "Certificat de propriété",
    value: "CER_PRO",
    icon: "",
  },
  {
    label: "Certificat de mutation de propriété",
    value: "CER_MUT_PRO",
    icon: "",
  },
];

export default docs;
