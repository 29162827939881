import { createStyles, rem } from "@mantine/core";
import { colors } from "../../../configs";
const commandesStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    padding: "3% 2%",
    backgroundColor: "#c6d2d2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 25,
  },
  tabsBox: {
    padding: "5px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
}));

export default commandesStyles;
