import React from "react";
import { Box, Text } from "@mantine/core";
import hwiStyles from "../AcdHiw/hiwStyles";

const HiwStep = ({ icon, text }: any) => {
  const { classes } = hwiStyles();

  return (
    <Box className={classes.hiwStepWrapper}>
      <Box className={classes.hiwstepIconBox}>{icon}</Box>
      <Box>
        <Text className={classes.hiwStepText}>{text}</Text>
      </Box>
    </Box>
  );
};

export default HiwStep;
