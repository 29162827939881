import { createStyles } from "@mantine/core";
import { colors } from "../../../configs";
const wusStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.secondaryLight,

    gap: 25,

    [theme.fn.largerThan("xl")]: { padding: "30px 8%" },
    [theme.fn.smallerThan("xl")]: { padding: "24px 5%" },
    [theme.fn.smallerThan("lg")]: { padding: "21px 5%" },
    [theme.fn.smallerThan("md")]: { padding: "18px 2%" },
    [theme.fn.smallerThan("sm")]: { padding: "15px 1%" },
    [theme.fn.smallerThan("xs")]: { padding: "15px 1%" },
  },

  wusTitleBox: {
    maxWidth: "75%",
    textAlign: "center",

    [theme.fn.largerThan("xl")]: { maxWidth: "75%" },
    [theme.fn.smallerThan("xl")]: { maxWidth: "75%" },
    [theme.fn.smallerThan("lg")]: { maxWidth: "80%" },
    [theme.fn.smallerThan("md")]: { maxWidth: "100%" },
    [theme.fn.smallerThan("sm")]: { maxWidth: "100%" },
    [theme.fn.smallerThan("xs")]: { maxWidth: "100%" },
  },
  wusTitle: {
    fontSize: "36px",
    color: colors.primary,
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "1.1",

    [theme.fn.largerThan("xl")]: { fontSize: "36px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "36px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "36px" },
    [theme.fn.smallerThan("md")]: { fontSize: "36px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "24px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "24px" },
  },
  wuStepsbox: {
    //  padding: "15px 5%",
    /*
    [theme.fn.largerThan("xl")]: { padding: "15px 15%" },
    [theme.fn.smallerThan("xl")]: { padding: "15px 15%" },
    [theme.fn.smallerThan("lg")]: { padding: "15px 15%" },
    [theme.fn.smallerThan("md")]: { padding: "15px 5%" },
    [theme.fn.smallerThan("sm")]: { padding: "15px 3%" },
    [theme.fn.smallerThan("xs")]: { padding: "15px 2%" },
    */
  },
  hiwActionBtn: {
    backgroundColor: colors.primary,
    height: "50px",
    width: "300px",
    borderRadius: "25px",
  },
  wuStepsGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  // css for step
  wusStepWrapper: {
    margin: "5px 5px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 25,
    width: "40%",
    padding: "5px",
    minHeight: "190px",
    /*
    [theme.fn.largerThan("xl")]: { width: "50%" },
    [theme.fn.smallerThan("xl")]: { width: "50%" },
    [theme.fn.smallerThan("lg")]: { width: "50%" },
    [theme.fn.smallerThan("md")]: { width: "50%" },
      [theme.fn.smallerThan("sm")]: { width: "50%" },
    */
    [theme.fn.smallerThan("xs")]: { width: "100%" },
  },
  hiwstepIconBox: {
    padding: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `5px solid ${colors.primary}`,
    backgroundColor: "#e2e9e8",
    borderRadius: 5,
  },
  hiwStepText: {},
}));

export default wusStyles;
