import React from "react";
import { useLocation } from "react-router-dom";

const Password = () => {
  const location = useLocation();
  const { userId } = location.state;
  return <div>Password</div>;
};

export default Password;
