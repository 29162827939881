import { createStyles } from "@mantine/core";
import { colors } from "../../../../configs";

const userCardStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: colors.secondary,
  },
  confirmButon: {
    alignSelf: "center",
    width: "100%",
    marginTop: "15px",
    backgroundColor: colors.secondary,
  },
  inputs: {
    padding: "5px",
    label: {
      color: "white",
    },
  },
}));
export default userCardStyles;
