import React from "react";
import Footer from "../../components/Footer/Footer";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import IntroBanner from "../../components/IntroBanner/IntroBanner";
import Navbar from "../../components/Navbar/Navbar";
import SellExpert from "../Vente/SellExpert/SellExpert";
import ManageHero from "./ManageHero/ManageHero";

const Gestion = () => {
  return (
    <>
      <HeaderBar />
      <Navbar />
      <ManageHero />
      <IntroBanner />
      <SellExpert />
      <Footer />
    </>
  );
};

export default Gestion;
