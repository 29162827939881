import { createStyles, em, getBreakpointValue, rem } from "@mantine/core";
import { colors } from "../../../configs";
const heroStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    background: "#c6d2d2",
    height: "80vh",

    [theme.fn.largerThan("xl")]: { padding: "6px 8%", height: "80vh" },
    [theme.fn.smallerThan("xl")]: { padding: "6px 8%", height: "75vh" },
    [theme.fn.smallerThan("lg")]: { padding: "6px 8%", height: "70vh" },
    [theme.fn.smallerThan("md")]: { padding: "3px 7px", height: "65vh" },
    [theme.fn.smallerThan("sm")]: { padding: "3px 7px", height: "60vh" },
    [theme.fn.smallerThan("xs")]: {
      padding: "2px 5px",
      height: "60vh",
    },

    gap: 5,
  },
  firstBox: {
    flex: 1,
    margin: "1%",
  },
  fbTitle: {
    marginTop: "3%",

    color: colors.primary,
    fontWeight: "bolder",
    fontSize: "36px",
    textAlign: "center",
    lineHeight: "1.1",
    [theme.fn.largerThan("xl")]: { fontSize: "36px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "36px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "36px" },
    [theme.fn.smallerThan("md")]: { fontSize: "36px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "24px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "24px" },
  },
  fbbr: {
    display: "flex",
    [theme.fn.smallerThan("sm")]: { display: "flex" },
    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
  sndBox: {
    flex: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sbDescBox: {
    flex: 3.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  sbdbText: {
    fontSize: "20px",
    color: colors.primary,
    marginBottom: "50px",
    [theme.fn.largerThan("xl")]: { fontSize: "20px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "20px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "20px" },
    [theme.fn.smallerThan("md")]: { fontSize: "20px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "18px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "15px" },
  },
  sbdbBtn: {
    backgroundColor: colors.primary,
    height: "50px",
    width: "300px",
    borderRadius: "25px",
    [theme.fn.smallerThan("sm")]: {
      marginBottom: "20px",
    },
    [theme.fn.smallerThan("xs")]: {
      marginBottom: "18px",
      textAlign: "center",
      alignSelf: "center",
    },
  },
  sbImgBox: {
    flex: 2.5,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    [theme.fn.smallerThan("xs")]: { display: "none" },
  },
  sbibImg: {
    width: "80%",
  },
}));

export default heroStyles;
