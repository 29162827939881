import React from "react";

import { Title, Text, Tabs, Box } from "@mantine/core";
import LOGO from "../../assets/images/TransLogo.jpeg";
import { IconMail, IconPhone } from "@tabler/icons-react";
import PhoneRegisterForm from "../../forms/PhoneRegisterForm";
import EmailRegisterForm from "../../forms/EmailRegisterForm";
import { Link } from "react-router-dom";
import registerStyles from "./registerStyles";

const Register = () => {
  const { classes } = registerStyles();
  return (
    <div className={classes.wrapper}>
      <Box className={classes.left}>
        <Box className={classes.top}>
          <Title className={classes.title} ta="center" mt="md" mb={5}>
            Transparence Immobilière
          </Title>
          <img
            className={classes.logo}
            src={LOGO}
            alt="Transparence Immobilière"
            width="75"
          />

          <Title className={classes.helpText} ta="center" mt="md">
            Inscription flash!
          </Title>
        </Box>
        <Box className={classes.center}>
          <Tabs color="teal" defaultValue="email">
            <Tabs.List grow>
              <Tabs.Tab value="email" icon={<IconMail size="0.8rem" />}>
                Email
              </Tabs.Tab>
              <Tabs.Tab value="phone" icon={<IconPhone size="0.8rem" />}>
                Numéro
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="email" pt="xs">
              <EmailRegisterForm />
            </Tabs.Panel>

            <Tabs.Panel value="phone" pt="xs">
              <PhoneRegisterForm />
            </Tabs.Panel>
          </Tabs>
        </Box>
        <Box className={classes.bottom}>
          <Text ta="center" className={classes.bottomText}>
            Vous avez déjà un compte ?
          </Text>
          <Link to="/connexion" className={classes.bottomLink}>
            Connectez-vous
          </Link>
        </Box>
      </Box>
      <Box className={classes.right}></Box>
    </div>
  );
};
export default Register;
