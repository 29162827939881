import { Box, SimpleGrid, Title } from "@mantine/core";
import { IconHomeDollar, IconRoad, IconSearch } from "@tabler/icons-react";
import React from "react";
import { colors } from "../../configs";
import ServiceCard from "./ServiceCard";
import ServicesStyles from "./ServicesStyles";

const Services = () => {
  const { classes } = ServicesStyles();

  return (
    <Box id="services" className={classes.wrapper}>
      <Title order={1} className={classes.title}>
        Tout nos services en détails
      </Title>
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: "xl", cols: 3, spacing: "xl" },
          { maxWidth: "lg", cols: 3, spacing: "lg" },
          { maxWidth: "md", cols: 3, spacing: "md" },
          { maxWidth: "sm", cols: 2, spacing: "sm" },
          { maxWidth: "xs", cols: 1, spacing: "xs" },
        ]}>
        <ServiceCard
          sList={[
            "Avant toute signature et toute transaction financière, laisser notre équipe d'experts vérifier minutieusement les documents du terrain ou de la maison que vous envisagez acheter. Nous nous assurons que tout est en régle et confrome au réglement en vigueur.",
          ]}
          title={"Vérification avant achat de terrain"}
          icon={<IconSearch color="pink" size="40" fontWeight="bolder" />}
        />
        <ServiceCard
          sList={[
            "Nous vous offrons avisés et un accompagnement personalisé tout au long du processus de votre demande d'ACD.Vous êtes informés à chaque étape, prenant des décisions éclairées.",
          ]}
          title={"Accompagnement démarches d'ACD"}
          icon={<IconRoad color="blue" size="40" fontWeight="bolder" />}
        />
        <ServiceCard
          sList={[
            "En plus nous vous proposons une sélection soignesement choisie de terrains ou biens immobiliers de qualité.Chaque bien que nous proposons passe d'abord par notre rigoux processus d'évaluation pour vous garantir une opportunité d'investissement solide.",
          ]}
          title={"Achat et vente de térrains et maisons"}
          icon={<IconHomeDollar color="brown" size="40" fontWeight="bolder" />}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Services;
