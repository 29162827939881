import React, { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { authentication, db } from "../firebase-config";
import { doc, updateDoc } from "firebase/firestore";
import countries from "../shared/countries";
import useFirebaseAuth from "../context/authuser/useFirebaseAuth";
import { Button, LoadingOverlay, Select, Text, TextInput } from "@mantine/core";
import phoneStyles from "./phoneStyles";
import { useForm } from "@mantine/form";
import { getCountryLabel } from "../utils/getLabels";
import { colors } from "../configs";

const PhoneLoginForm = () => {
  const form = useForm({
    initialValues: {
      country: "",
      phoneNumber: "",
    },
    validate: {
      country: (value) => value.trim().length < 2,
      phoneNumber: (value) => value.trim().length < 8,
    },
  });
  const otpForm = useForm({
    initialValues: {
      otp: "",
    },
    validate: {
      otp: (value) => value.trim().length < 6,
    },
  });
  const { classes } = phoneStyles();

  const navigate = useNavigate();
  const { loggedUser } = useFirebaseAuth();
  const [loading, setLoading] = useState(false);
  //  const [checkLoading, setCheckLoading] = useState(false);
  //  const [otp, setOtp] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [showPhone, setShowPhone] = useState<boolean>(true);

  const generateRecaptcha = () => {
    setLoading(true);
    setErrorMsg("");
    setSuccessMsg("");
    window.recaptchaVerifier = new RecaptchaVerifier(
      authentication,
      "ti_sign_up_submit",
      {
        size: "invisible",
        callback: (response: any) => {
          setSuccessMsg("Humain vérifié, envoie du code en cours...");
        },
        "expired-callback": (expResponse: any) => {
          setErrorMsg(
            "Nous avons rencontré un soucis lors de l'envoie du code de vérification, rafraichissez la page et recommencez."
          );
        },
      }
      //  authentication
    );
  };
  // Send OTP
  const requestOTP = (data: any) => {
    setLoading(true);

    setErrorMsg("");
    setSuccessMsg("");
    console.warn("phone data: ", data);
    const phoneN = `+${getCountryLabel(data.country).phone}${data.phoneNumber}`;
    console.log("PHONE NUMBER: ", phoneN);

    //  e.preventDefault();
    if (data.phoneNumber.length >= 6) {
      generateRecaptcha();
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, phoneN, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setSuccessMsg(
            "Un Sms contenant le code de vérification vous a été envoyé au numéro indiqué. Saisissez-le svp."
          );
          setTimeout(() => {
            setShowPhone(false);
          }, 1000);
        })
        .catch((error) => {
          console.log("====================================");
          console.log("REQUEST OTP VERIFIER: ", error);
          console.log("====================================");
          setLoading(false);
          switch (error) {
            case "auth/invalid-phone-number":
              setErrorMsg("Numéro de téléphone invalide.");
              break;
            case "auth/missing-phone-number":
              setErrorMsg("Numéro de téléphone manquant.");
              break;
            case "auth/network-request-failed":
              setErrorMsg(
                "Erreur de réseaux, veuillez réssayer, si l'erreur persiste contactez notre support via: support@bettorassistant.com"
              );
              break;
            case "auth/too-many-requests":
              setErrorMsg("Trop de tentative réessayez dans une demie heure.");
              break;
            default:
              setErrorMsg(
                "Erreur inconnue, raffraichissez la page puis réessayez. Si l'erreur persiste, contactez notre support via: support@bettorassistant.com"
              );
              break;
          }

          setTimeout(() => {
            setShowPhone(true);
          }, 2000);
        });
    }
  };
  const verifyOTP = (otp: string) => {
    console.warn("INSERTED OTP: ", otp);

    setErrorMsg("");
    setSuccessMsg("");
    if (otp.trim().length === 6) {
      setLoading(true);

      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then(async (result: any) => {
          // User signed in successfully.
          const user = result.user;
          console.warn("LOGGED USER: ", user);

          setSuccessMsg("Connexion en cours...");
          try {
            await updateDoc(doc(db, "users", user.uid), {
              lastLogin: new Date().getTime(),
            });

            Cookies.set("auth_token", user.uid);
            setLoading(false);
            setSuccessMsg("Connexion réussie, redirection en cours...");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } catch (e) {
            setLoading(false);
            setErrorMsg("Erreur, actualisez la page puis recommencez.");
            console.error("Error adding document: ", e);
          }
          // ...
        })
        .catch((error: any) => {
          setLoading(false);
          console.error("LOGIN ERROR: ", error.code);
          switch (error.code) {
            case "auth/code-expired":
              setErrorMsg(
                "Code expiré, raffraichissez la page pour reprendre le processus."
              );
              break;
            case "auth/credential-already-in-use":
              setErrorMsg("Code déja utilisé.");
              break;
            case "auth/internal-error":
              setErrorMsg("Erreur interne, réessayez encore.");
              break;
            case "auth/invalid-verification-code":
              setErrorMsg("Code invalide, vérifiez bien le code reçu.");
              break;
            case "auth/network-request-failed":
              setErrorMsg(
                "Problème de réseau, vérifiez votre connexion internet."
              );
              break;
            case "auth/too-many-requests":
              setErrorMsg("Trop de tentatives, réessayez plus tard.");
              break;
            default:
              break;
          }
        });
    } else {
      setErrorMsg("Le code est composé de 6 chiffres.");
    }
  };

  return (
    <>
      {showPhone ? (
        <>
          <form onSubmit={form.onSubmit((data) => requestOTP(data))}>
            <Select
              name="country"
              className={classes.inputs}
              searchable
              label="Pays de résidence"
              placeholder="Cliquez-ici"
              nothingFound="Aucune correspondance"
              data={countries}
              clearable
              {...form.getInputProps("country")}
            />

            <TextInput
              name="phoneNumber"
              type="tel"
              label="Votre numéro de téléphone"
              className={classes.inputs}
              {...form.getInputProps("phoneNumber")}
            />
            <Text variant="body2" color="success">
              {successMsg}
            </Text>
            <Text variant="body2" color="error">
              {errorMsg}
            </Text>
            <Button
              id="ti_sign_up_submit"
              className={classes.confirmButon}
              // onClick={() => requestOTP(phoneNumber)}
              type="submit">
              Confirmer
            </Button>
          </form>
          {loading && (
            <>
              <LoadingOverlay
                style={{
                  color: "#000000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "0 auto",
                }}
                visible={loading}
                overlayBlur={2}
                onClick={() => null}
              />
            </>
          )}
        </>
      ) : (
        <>
          <form>
            <TextInput
              label={"Veuillez saisir le code SMS reçu."}
              className={classes.inputs}
              type="number"
              name="otp"
              //  onChange={(e: any) => verifyOTP(e.target.value)}
              {...form.getInputProps("otp")}
              onChange={(val) => verifyOTP(val.target.value)}
            />
          </form>
          <Text
            mt="15px"
            variant="body2"
            color="success"
            ta="center"
            c={colors.primary}
            fw="bold">
            {successMsg}
          </Text>
          <Text variant="body2" color="error">
            {errorMsg}
          </Text>

          {loading && (
            <>
              <LoadingOverlay
                style={{
                  color: "#000000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "0 auto",
                }}
                visible={loading}
                overlayBlur={2}
                onClick={() => null}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default PhoneLoginForm;
