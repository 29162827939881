import React from "react";
import TransLogo from "../../assets/images/TransLogo.jpeg";
import {
  IconKey,
  IconMenu2,
  IconUserPlus,
  IconCircleKeyFilled,
  IconUser,
} from "@tabler/icons-react";
import { Box, Menu, Text, ThemeIcon } from "@mantine/core";
import NavLink from "./NavLink";
import Navbutton from "./Navbutton";
import navbarStyles from "./navbarStyles";
import { colors } from "../../configs";
import useFirebaseAuth from "../../context/authuser/useFirebaseAuth";
import { Link } from "react-router-dom";
const navLinks = [
  {
    title: "Accueil",
    link: "",
  },
  {
    title: "Nos services",
    link: "#services",
  },
  {
    title: "Notre vision",
    link: "#vision",
  },
  {
    title: "Actualités",
    link: "#actualities",
  },
];

const navBtns = [
  {
    title: "Inscription",
    link: "inscription",
    icon: <IconUserPlus />,
    bg: "",
  },
  {
    title: "Connexion",
    link: "connexion",
    icon: <IconKey />,
    bg: "",
  },
];
const Navbar = () => {
  const { classes } = navbarStyles();
  const { loggedUser, authUser } = useFirebaseAuth();
  /*
  console.warn("aut user: ", authUser);
  console.error("logged user: ", loggedUser);

  console.warn("AUT TOKEN: ", Cookies.get("auth_token"));
  console.warn("AUT NAME: ", Cookies.get("auth_name"));
  console.warn("AUT FIRSTNAME: ", Cookies.get("auth_fname"));
*/
  return (
    <Box className={classes.wrapper} id="Navbar">
      {/* left */}
      <Box className={classes.leftNav} component="a" href="/">
        <Box className="n-name">
          <img
            src={TransLogo}
            className={classes.logo}
            alt="Transparence Immobilière"
          />
        </Box>
        <Box className={classes.siteTitleBox}>
          <Text className={classes.siteTitle}>
            Transparence <br />
            Immobilière
          </Text>
        </Box>
      </Box>
      <Box className={classes.centerNav}>
        {navLinks.map((nLink, nlIdx) => (
          <NavLink key={nlIdx} link={nLink.link} title={nLink.title} />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 5,
        }}>
        <Box className={classes.mobileRightNav}>
          {loggedUser && authUser ? (
            <Link
              to="/profile"
              state={{ userId: loggedUser }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                textDecoration: "none",
              }}>
              <ThemeIcon
                size="lg"
                variant="gradient"
                gradient={{ from: colors.primary, to: colors.primary }}>
                <IconUser size="1.2rem" />
              </ThemeIcon>
              <Text
                style={{
                  display: "flex",
                  fontSize: "13px",
                  marginLeft: "5px",
                  color: colors.primary,
                }}>
                Mon compte
              </Text>
            </Link>
          ) : (
            <>
              <Menu>
                <Menu.Target>
                  <ThemeIcon
                    size="lg"
                    variant="gradient"
                    gradient={{
                      from: colors.primaryLight,
                      to: colors.primaryLight,
                    }}>
                    <IconCircleKeyFilled />
                  </ThemeIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  {navBtns.map((nBtn, btnIdx) => (
                    <Menu.Item key={btnIdx}>
                      <Navbutton
                        link={nBtn.link}
                        icon={nBtn.icon}
                        bgColor={nBtn.bg}
                        title={nBtn.title}
                      />
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </>
          )}
        </Box>
        <Box className={classes.mobileCenterNav}>
          <Menu>
            <Menu.Target>
              <ThemeIcon size="lg" variant="default">
                <IconMenu2 color="#425655" />
              </ThemeIcon>
            </Menu.Target>

            <Menu.Dropdown>
              {navLinks.map((nLink, nlIdx) => (
                <Menu.Item key={nlIdx}>
                  <NavLink key={nlIdx} link={nLink.link} title={nLink.title} />
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Box>
      {/* right */}
      <Box className={classes.rightNav}>
        {loggedUser && authUser ? (
          <Link
            to="/profile"
            state={{ userId: loggedUser }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textDecoration: "none",
            }}>
            <ThemeIcon
              size="lg"
              variant="gradient"
              gradient={{ from: colors.primary, to: colors.primary }}>
              <IconUser size="1.2rem" />
            </ThemeIcon>
            <Text
              style={{
                display: "flex",
                fontSize: "13px",
                marginLeft: "5px",
                color: colors.primary,
              }}>
              Mon compte
            </Text>
          </Link>
        ) : (
          <>
            {navBtns.map((nBtn, btnIdx) => (
              <Navbutton
                key={btnIdx}
                link={nBtn.link}
                icon={nBtn.icon}
                bgColor={nBtn.bg}
                title={nBtn.title}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Navbar;
