import React from "react";

import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  createStyles,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { addDoc, collection } from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";
import { colors } from "../../../configs";
import { db } from "../../../firebase-config";
import { useLocation } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    backgroundColor: colors.secondary,

    /*
    padding: "3% 20%",

    [theme.fn.largerThan("xl")]: { padding: "5%  25%" },
    [theme.fn.smallerThan("xl")]: { padding: "5%  20%" },
    [theme.fn.smallerThan("lg")]: { padding: "5%  20%" },
    [theme.fn.smallerThan("md")]: { padding: "4%  7%" },
    [theme.fn.smallerThan("sm")]: { padding: "3%  7%" },
    [theme.fn.smallerThan("xs")]: {
      padding: "5% 2%",
    },
    */
  },
  form: {},
}));
const EditProfile = () => {
  const location = useLocation();
  const { userId } = location.state;
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      name: "",
      firstname: "",
      phoneNumber: "",
      email: "",
    },
    validate: {
      name: (value) => value.trim().length < 2,
      firstname: (value) => value.trim().length < 2,
      phoneNumber: (value) => value.trim().length === 8,

      email: (value) => !/^\S+@\S+$/.test(value),
      //  subject: (value) => value.trim().length === 0,
    },
  });

  const sendComment = async (reqData: any) => {
    //  reqData.requesterId = loggedUser;
    reqData.requesterId = "loggedUser";
    toast.promise(addDoc(collection(db, "comments"), reqData), {
      loading: "Envoi en cours...",
      success: (
        <b>
          Envoyé, des preuves pourraient vous êtes demandés avant affichage de
          votre commentaire sur notre site, Merci!
        </b>
      ),
      error: <b>Désolé,réessayez.</b>,
    });
    /*
      
    await addDoc(collection(db, "comments"), reqData)
      .then((saveSucces: any) => {
        console.log("Document written with ID: ", saveSucces.id);
      })
      .catch((saveError: any) => {
        console.error("ERROR SAVING YOUR REQUEST: ", saveError);
      });
      */
  };
  return (
    <>
      <Toaster
        toastOptions={{
          //  className: "",
          style: {
            //border: "1px solid #713200",
            padding: "20px",
            // color: "#713200",
            fontSize: "21px",
          },
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
        position="top-center"
      />
      <Box className={classes.wrapper}>
        <form
          onSubmit={form.onSubmit((data) => sendComment(data))}
          className={classes.form}>
          <Title order={2} size="h2" weight={900} align="center">
            Votre avis compte pour nous!
          </Title>

          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
            <TextInput
              label="Nom et prénoms"
              placeholder="Votre nom et prénom(s)"
              name="name"
              variant="filled"
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Email"
              placeholder="Votre adresse mail"
              name="email"
              variant="filled"
              {...form.getInputProps("email")}
            />
          </SimpleGrid>

          <TextInput
            label="Sujet"
            placeholder="Indiquez un sujet"
            mt="md"
            name="subject"
            variant="filled"
            {...form.getInputProps("subject")}
          />
          <Textarea
            mt="md"
            label="Message"
            placeholder="Votre message"
            maxRows={10}
            minRows={5}
            autosize
            name="message"
            variant="filled"
            {...form.getInputProps("message")}
          />

          <Group position="center" mt="xl">
            <Button
              type="submit"
              size="md"
              sx={{ backgroundColor: colors.primary }}>
              Envoyer
            </Button>
          </Group>
        </form>
      </Box>
    </>
  );
};
export default EditProfile;
