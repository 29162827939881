import { Box } from "@mantine/core";
import React from "react";
import Footer from "../../components/Footer/Footer";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import IntroBanner from "../../components/IntroBanner/IntroBanner";
import Navbar from "../../components/Navbar/Navbar";
import AcdExpert from "./AcdExpert/AcdExpert";
import AcdFaq from "./AcdFaq/AcdFaq";
import AcdHero from "./AcdHero/AcdHero";
import AcdHiw from "./AcdHiw/AcdHiw";
import AcdWus from "./AcdWus/AcdWus";

const Acd = () => {
  return (
    <>
      <HeaderBar />
      <Navbar />
      <AcdHero />
      <IntroBanner />
      <AcdHiw />
      <AcdWus />
      <AcdFaq />
      <AcdExpert />
      <Footer />
    </>
  );
};

export default Acd;
