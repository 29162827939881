import React from "react";
import { Box, Text } from "@mantine/core";

import { Icon3dCubeSphere } from "@tabler/icons-react";
import WusCat from "../checkcomponents/WusCat";
import wusStyles from "./wusStyles";

const whyUsCats = [
  {
    title: "Simplicité",
    texts: [
      "Demande d'obtention d'ACD100% en ligne",
      "Pas besoin de vous déplacer pourdes démarches fastidieuses",
    ],
    icon: <Icon3dCubeSphere />,
    iconColor: "#07B1FB",
  },
  {
    title: "Confiance",
    texts: [
      "Confiez votre obtention d'ACD àLegafrik",
      "Nous avons accompli plus de20 000 formalités juridiques",
    ],
    icon: <Icon3dCubeSphere />,
    iconColor: "#0AE14B",
  },
  {
    title: "Fiable",
    texts: [
      "Vérification de l'authenticité des documents",
      "Dépôt de votre dossier au ministère de la constuction",
    ],
    icon: <Icon3dCubeSphere />,
    iconColor: "#016699",
  },
  {
    title: "Humain",
    texts: [
      "Accompagnement par des expertspour éviter les erreurs coûteuses",
      "Profitez d'un support juridique à tout moment",
    ],
    icon: <Icon3dCubeSphere />,
    iconColor: "#CD71AB",
  },
];
const CheckWus = () => {
  const { classes } = wusStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.wusTitleBox}>
        <Text className={classes.wusTitle}>
          Pourquoi choisir TransparenceImmobilière pour l’obtention de votre ACD
          ?
        </Text>
      </Box>
      <Box className={classes.wuStepsbox}>
        <Box className={classes.wuStepsGrid}>
          {whyUsCats.map((cat, cIdx) => (
            <WusCat
              key={cIdx}
              icon={cat.icon}
              title={cat.title}
              texts={cat.texts}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CheckWus;
