import cities from "../shared/citiies";
import countries from "../shared/countries";
import docsList from "../shared/docsList";
import models from "../shared/modeles";

const getDocLabel = (value: string) => {
  const foundDoc = docsList.filter((doc) => doc.value === value)[0];
  //  console.log("founded doc : ", foundDoc);

  return foundDoc.label;
};
const getModelLabel = (value: string) => {
  const foundDoc = models.filter((doc) => doc.value === value)[0];
  //  console.log("founded doc : ", foundDoc);

  return foundDoc.label;
};
const getCityLabel = (value: string) => {
  const foundDoc = cities.filter((doc) => doc.value === value)[0];
  //  console.log("founded doc : ", foundDoc);

  return foundDoc.admin_name;
};
const getCountryLabel = (value: string) => {
  const foundDoc = countries.filter((doc) => doc.value === value)[0];
  //  console.log("founded doc : ", foundDoc);

  return foundDoc;
};
export { getDocLabel, getModelLabel, getCityLabel, getCountryLabel };
