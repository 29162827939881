import React, { useRef, useState } from "react";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../firebase-config";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import Doc from "../../models/Doc";
import {
  Button,
  createStyles,
  Group,
  rem,
  Text,
  SimpleGrid,
  Progress,
  Select,
} from "@mantine/core";
import { IconCloudUpload, IconDownload, IconX } from "@tabler/icons-react";
import { Dropzone, MIME_TYPES, FileWithPath } from "@mantine/dropzone";
import { colors } from "../../configs";
import useFirebaseAuth from "../../context/authuser/useFirebaseAuth";
import docsList from "../../shared/docsList";
import { sendNotifs } from "../../utils/notifs";
import { getDocLabel } from "../../utils/getLabels";
import PaymentCard from "../PaymentCard/PaymentCard";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: rem(30),
  },

  dropzone: {
    borderWidth: rem(1),
    paddingBottom: rem(50),
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: "absolute",
    width: rem(250),
    left: `calc(50% - ${rem(125)})`,
    bottom: rem(-20),
  },
}));

const UploaDocument = ({ modelId, docType }: any) => {
  const { classes, theme } = useStyles();
  const { loggedUser } = useFirebaseAuth();

  const openRef = useRef<() => void>(null);
  const [files, setFiles] = useState<FileWithPath[]>([]);

  //  const [file, setFile] = useState<any>(null); // progress
  const [percent, setPercent] = useState(0);

  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState<any>(docType);
  const [saved, setSaved] = useState(false);

  const handleUpload = () => {
    setLoading(true);
    //  setFile(files[0]);
    /*
    if (!file) {
      alert("Please upload an image first!");
    }
*/
    if (files.length <= 0) {
      alert("Please upload an image first!");
    }

    for (let index = 0; index < files.length; index++) {
      const fileEl = files[index];
      const storageRef = ref(storage, `/files/${fileEl.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.

      const uploadTask = uploadBytesResumable(storageRef, fileEl);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ); // update progress

          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            //  setDocumentLink(url);
            console.warn("THE UPLOADED LINK: ", url);

            if (url !== null && url !== "") {
              saveRequest(url, fileEl);
              setPercent(0);

              if (index === files.length - 1) {
                setLoading(false);
                setFiles([]);
                setSaved(true);
              }
            }
          });
        }
      );
    }
  };

  const saveRequest = async (doclINK: string, file: FileWithPath) => {
    const docRef = doc(db, "requests", modelId);
    const docu: Doc = {
      link: doclINK,
      name: file.name,
      type: documentType,
      ext: file.type,
      from: "ADMIN", // is it uploaded by admin or customer
      createdAt: new Date().getTime(),
      uid: loggedUser,
    };

    console.warn("DOCUMENT INFO to pass: ", docu);
    const docsUnion = {
      docsList: arrayUnion(docu),
    };
    updateDoc(docRef, docsUnion)
      .then(() => {
        alert("Ok");
        sendNotifs({
          sender: loggedUser,
          destination: "ADMIN",
          concernId: modelId, // from what ?
          subject: "Nouveau document",
          message: "Mr... N'da Yapo vient d'ajouter son: " + documentType,
          createdAt: new Date().getTime(),
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <img
        style={{
          height: "60px",
          maxHeight: "60px",
          border: `3px solid ${colors.secondary}`,
        }}
        key={index}
        src={imageUrl}
        alt={file.name}
        // imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  });
  /*
  useEffect(() => {
    setFile(files[0]);
  }, [files]);
  */
  return (
    <>
      <div>
        <div className={classes.wrapper}>
          {docType ? (
            <Text>Veuillez uploader votre {`${getDocLabel(docType)}`} </Text>
          ) : (
            <Select
              sx={{
                margin: "12px 10px",
              }}
              searchable
              label="Type de document"
              placeholder="Cliquez-ici"
              nothingFound="Aucune correspondance"
              onChange={(doct) => setDocumentType(doct)}
              data={docsList}
              clearable
            />
          )}

          <Dropzone
            loading={loading}
            openRef={openRef}
            onDrop={setFiles}
            className={classes.dropzone}
            radius="md"
            accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf]}
            maxSize={30 * 1024 ** 2}
            multiple={true}
            maxFiles={3}>
            <div style={{ pointerEvents: "none" }}>
              <Group position="center">
                <Dropzone.Accept>
                  <IconDownload
                    size={rem(50)}
                    color={theme.primaryColor[6]}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size={rem(50)}
                    color={theme.colors.red[6]}
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload
                    size={rem(50)}
                    color={
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black
                    }
                    stroke={1.5}
                  />
                </Dropzone.Idle>
              </Group>

              <Text ta="center" fw={700} fz="lg" mt="xl">
                <Dropzone.Accept>
                  Glissez vos fichers directements ici( maximum 3 fichers
                  autorisés)
                </Dropzone.Accept>
                <Dropzone.Reject>
                  Que des fichers pdf, images(jpeg, jpg & png) en dessous de 5mb
                  sont autorisés
                </Dropzone.Reject>
                <Dropzone.Idle>Ajoutez votre document</Dropzone.Idle>
              </Text>
              <Text ta="center" fz="sm" mt="xs" c="dimmed">
                Nous acceptons seulement des fichers
                <i>.pdf, jpeg, png, jpg</i> avec une taille maximale de 5mb.
              </Text>
            </div>
          </Dropzone>

          <Button
            className={classes.control}
            size="md"
            radius="xl"
            onClick={() => openRef.current?.()}>
            Choisir le ficher
          </Button>
        </div>
        <div>
          <Text sx={{ textAlign: "center" }}>{percent}%</Text>
          <Progress
            value={percent}
            label={`${percent}`}
            size="xl"
            radius="xl"
            aria-valuenow={percent}
            animate={true}
            color={colors.primary}
          />
          <SimpleGrid
            style={{ maxHeight: "125px" }}
            cols={4}
            //  breakpoints={[{ maxWidth: "sm", cols: 4 }]}
            mt={previews.length > 0 ? "xl" : "sm"}>
            {previews}
          </SimpleGrid>
          <Button
            disabled={
              loading ||
              documentType == "" ||
              !documentType ||
              files.length == 0
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              textAlign: "center",
              margin: "0 auto",
            }}
            onClick={() => handleUpload()}
            mt={8}>
            Envoyer
          </Button>
        </div>
      </div>
    </>
  );
};

export default UploaDocument;
