import React from "react";

import {
  Card,
  Text,
  Group,
  createStyles,
  Center,
  rem,
  ThemeIcon,
  Modal,
  Tooltip,
} from "@mantine/core";
import {
  IconFilePlus,
  IconGauge,
  IconMap,
  IconMap2,
  IconMapPin,
  IconStepOut,
} from "@tabler/icons-react";
import { colors } from "../../../../configs";
import { useDisclosure } from "@mantine/hooks";
import UploaDocument from "../../../../components/UploadDocument/UploaDocument";
import Doc from "../../../../models/Doc";
import { getDocLabel } from "../../../../utils/getLabels";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    margin: "5px",
    width: "45%",
  },

  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[5],
  },
  detailsText: {
    color: colors.primary,
    fontWeight: "bold",
  },
}));

const PreAchatCard = ({ req }: any) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);

  const Features = () => {
    return (
      <>
        <Tooltip label="Ville" color={colors.secondary} withArrow>
          <Center>
            <IconMapPin size="1.05rem" className={classes.icon} stroke={1.5} />
            <Text size="xs" className={classes.detailsText}>
              {req?.ville}
            </Text>
          </Center>
        </Tooltip>
        <Tooltip label="Commune" color={colors.secondary} withArrow>
          <Center>
            <IconGauge size="1.05rem" className={classes.icon} stroke={1.5} />
            <Text size="xs" className={classes.detailsText}>
              {req?.commune}
            </Text>
          </Center>
        </Tooltip>

        <Tooltip label="Quartier" color={colors.secondary} withArrow>
          <Center>
            <Text size="xs" className={classes.detailsText}>
              {req?.quartier}
            </Text>
          </Center>
        </Tooltip>
        <Tooltip label="Surface totale" color={colors.secondary} withArrow>
          <Center>
            <IconMap size="1.05rem" className={classes.icon} stroke={1.5} />
            <Text size="xs" className={classes.detailsText}>
              {req?.surfaceTotal}(mètres carrés)
            </Text>
          </Center>
        </Tooltip>
        {req?.surfaceUsed && (
          <Tooltip
            label="Surface construite"
            color={colors.secondary}
            withArrow>
            <Center>
              <IconMap2 size="1.05rem" className={classes.icon} stroke={1.5} />
              <Text size="xs" className={classes.detailsText}>
                {req?.surfaceUsed}(mètres carrés)
              </Text>
            </Center>
          </Tooltip>
        )}
      </>
    );
  };

  /*
  const features = mockdata.map((feature) => (
    <Center key={feature.label}>
      <feature.icon size="1.05rem" className={classes.icon} stroke={1.5} />
      <Text size="xs">{feature.label}</Text>
    </Center>
  ));
*/
  const getType = (type: string): string => {
    switch (type) {
      case "TYPE_1":
        return "Terrain";
        break;
      case "TYPE_2":
        return "Construction";
        break;
      default:
        return "N/A";
        break;
    }
  };
  const getModele = (model: string): string => {
    switch (model) {
      case "preachat":
        return "Vérification avant achat";
        break;
      case "gestion":
        return "Gestion Locative";
        break;
      case "vente":
        return "Accompagnement Vente";
        break;
      case "acd":
        return "Accompagnement démarches ACD";
        break;
      default:
        return "N/A";
        break;
    }
  };
  return (
    <>
      <Card withBorder radius="md" className={classes.card}>
        {/* 
      <Card.Section className={classes.imageSection}>
        <Image src="https://i.imgur.com/ZL52Q2D.png" alt="Tesla Model S" />
      </Card.Section>
*/}
        <Group position="apart" mt="xs">
          <div>
            <Text fw={500}>{getType(req?.type)}</Text>
            <Text fz="xs" c="dimmed">
              {getModele(req?.model)}
            </Text>
          </div>
          <Group
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "flex-end",
              alignItems: "flex-end",
            }}>
            <Tooltip
              label="Ajouter une étape"
              color={colors.secondary}
              withArrow>
              <ThemeIcon
                size="lg"
                variant="gradient"
                gradient={{ from: colors.secondary, to: colors.secondary }}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => alert("ok")}>
                <IconStepOut size="1.2rem" />
              </ThemeIcon>
            </Tooltip>
            <Tooltip
              label="Ajouter un document"
              color={colors.secondary}
              withArrow>
              <ThemeIcon
                size="lg"
                variant="gradient"
                gradient={{ from: colors.primary, to: colors.primary }}
                style={{
                  cursor: "pointer",
                }}
                onClick={open}>
                <IconFilePlus size="1.2rem" />
              </ThemeIcon>
            </Tooltip>
          </Group>
          {/* 
                    <Badge variant="outline">25% off</Badge>

          */}
        </Group>

        <Card.Section className={classes.section} mt="md">
          <Text fz="sm" c="dimmed" className={classes.label}>
            Information basiques
          </Text>

          <Group spacing={8} mb={-8}>
            {<Features />}
          </Group>
        </Card.Section>

        <Card.Section className={classes.section}>
          <Text fz="sm" c="dimmed" className={classes.label}>
            Valeur
          </Text>
          <Group spacing={30}>
            <div>
              <Text fz="md" fw={500} sx={{ lineHeight: 1 }}>
                Fixe: {req?.value}FCFA
              </Text>
              {req?.monthly && (
                <Text fz="md" fw={700} sx={{ lineHeight: 1 }}>
                  Mensuelle: {req?.monthly}FCFA
                </Text>
              )}
            </div>
          </Group>
        </Card.Section>
        <Card.Section className={classes.section} mt="md">
          <Text fz="sm" c="dimmed" className={classes.label}>
            Documents
          </Text>

          {req.docsList.length > 0 ? (
            <Group spacing={8}>
              {req.docsList.map((doc: Doc, dIdx: any) => (
                <a
                  key={dIdx}
                  href={doc.link}
                  target="_blank"
                  style={{ fontSize: "13px" }}>
                  {getDocLabel(doc.type)}
                </a>
              ))}
            </Group>
          ) : (
            <Text>Aucun document</Text>
          )}
          <Text fz="xs" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={5}>
            Demande crée le {new Date(req.createdAt).toLocaleString()}
          </Text>
        </Card.Section>
      </Card>

      <Modal
        opened={opened}
        onClose={close}
        title="Ajouter nouveau document"
        centered>
        <UploaDocument modelId={req.id} docType={null} />
      </Modal>
    </>
  );
};
export default PreAchatCard;
