import { createContext, useContext } from "react";
import useFirebaseAuth from "./useFirebaseAuth";

const authUserContext = createContext({
  authUser: null,
  loggedUser: null,
  loading: true,
  emailAndPassLogin: async (email: string, password: string) => {},
  emailAndPassRegister: async (email: string, password: string) => {},
  logOut: async () => {},
});

export function AuthUserProvider({ children }: any) {
  const auth = useFirebaseAuth();
  return (
    <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>
  );
}

export const useAuth = () => useContext(authUserContext);
