import React from "react";
import { Box, createStyles, Text } from "@mantine/core";

const headerBarStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: "black",
    color: "white",

    [theme.fn.smallerThan("xl")]: { backgroundColor: "green" },
    [theme.fn.smallerThan("lg")]: { backgroundColor: "red" },
    [theme.fn.smallerThan("md")]: { backgroundColor: "yellow" },
    [theme.fn.smallerThan("sm")]: { backgroundColor: "blue" },
    [theme.fn.smallerThan("xs")]: { backgroundColor: "pink" },
  },
  txt: {
    [theme.fn.smallerThan("xl")]: { fontSize: "48px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "36px" },
    [theme.fn.smallerThan("md")]: { fontSize: "24px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "12px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "6px" },
  },
}));

const UiTest = () => {
  const { classes } = headerBarStyles();
  return (
    <Box className={classes.wrapper}>
      <Text className={classes.txt}>Hello world</Text>
    </Box>
  );
};

export default UiTest;
