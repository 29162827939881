import React from "react";
import { Box, Text, Image } from "@mantine/core";
import aboutStyles from "./aboutStyles";
import HAPPY_CUSTOMER from "../../../assets/images/happy_customer.jpeg";
const HomeAbout = () => {
  const { classes } = aboutStyles();

  return (
    <Box id="vision" className={classes.wrapper}>
      <Box className={classes.subWrapper}>
        <Box className={classes.left}>
          <Image src={HAPPY_CUSTOMER} />
        </Box>
        <Box className={classes.right}>
          <Text className={classes.title}>
            Transparence Immobilière, l'expert du foncier et de l'immobilier en
            Côte d'ivoire.
          </Text>
          <Text className={classes.subTitle}>
            Chaque individu mérite une expérience d'achat immobilière
            transparente et sans tracas. Notre équipe s'engage à vous fournir
            des informations précises, des conseils impartiaux, importants et
            précieux et une tranquilité d'esprit.
            <br />
            Faites le choix de la sécurité et de la transaprence. Choisissez
            nous pour toutes vos transactions immobilières en Côte d'ivoire.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeAbout;
