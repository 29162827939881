import { createStyles, rem } from "@mantine/core";
import { colors } from "../configs";

const phoneStyles = createStyles((theme) => ({
  wrapper: {
    padding: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  stackBox: {},

  inputs: {
    width: "100%",
    fontSize: "21px",
  },
  confirmButon: {
    marginTop: "20px",
    width: "100%",
    backgroundColor: colors.primary,
  },
}));

export default phoneStyles;
