import React from "react";

import { Title, Text, Tabs, Box } from "@mantine/core";
import LOGO from "../../assets/images/TransLogo.jpeg";
import { IconMail, IconPhone } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import loginStyles from "./loginStyles";
import EmailLoginForm from "../../forms/EmailLoginForm";
import PhoneLoginForm from "../../forms/PhoneLoginForm";

const Register = () => {
  const { classes } = loginStyles();
  return (
    <div className={classes.wrapper}>
      <Box className={classes.left}>
        <Box className={classes.top}>
          <Title className={classes.title} ta="center" mt="md" mb={5}>
            Transparence Immobilière
          </Title>
          <img
            className={classes.logo}
            src={LOGO}
            alt="Transparence Immobilière"
            width="75"
          />

          <Title className={classes.helpText} ta="center" mt="md">
            Heureux ee vous revoir!
          </Title>
        </Box>
        <Box className={classes.center}>
          <Tabs color="teal" defaultValue="email">
            <Tabs.List grow>
              <Tabs.Tab value="email" icon={<IconMail size="0.8rem" />}>
                Email
              </Tabs.Tab>
              <Tabs.Tab value="phone" icon={<IconPhone size="0.8rem" />}>
                Numéro
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="email" pt="xs">
              <EmailLoginForm />
            </Tabs.Panel>

            <Tabs.Panel value="phone" pt="xs">
              <PhoneLoginForm />
            </Tabs.Panel>
          </Tabs>
        </Box>
        <Box className={classes.bottom}>
          <Text ta="center" className={classes.bottomText}>
            Pas encore de compte ?
          </Text>
          <Link to="/inscription" className={classes.bottomLink}>
            Inscrivez-vous
          </Link>
        </Box>
      </Box>
      <Box className={classes.right}></Box>
    </div>
  );
};
export default Register;
