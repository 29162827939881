import { createStyles } from "@mantine/core";
import { colors } from "../../../configs";
const hwiStyles = createStyles((theme) => ({
  wrapper: {
    margin: "20px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 25,

    [theme.fn.largerThan("xl")]: { padding: "30px 8%" },
    [theme.fn.smallerThan("xl")]: { padding: "24px 8%" },
    [theme.fn.smallerThan("lg")]: { padding: "21px 8%" },
    [theme.fn.smallerThan("md")]: { padding: "18px 7px" },
    [theme.fn.smallerThan("sm")]: { padding: "15px 7px" },
    [theme.fn.smallerThan("xs")]: { padding: "15px 5px" },
  },

  hiwTitle: {
    fontSize: "36px",
    color: colors.primary,
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "1.1",

    [theme.fn.largerThan("xl")]: { fontSize: "36px" },
    [theme.fn.smallerThan("xl")]: { fontSize: "36px" },
    [theme.fn.smallerThan("lg")]: { fontSize: "36px" },
    [theme.fn.smallerThan("md")]: { fontSize: "36px" },
    [theme.fn.smallerThan("sm")]: { fontSize: "24px" },
    [theme.fn.smallerThan("xs")]: { fontSize: "24px" },
  },
  hiwStepsbox: {
    padding: "15px 15%",
    [theme.fn.largerThan("xl")]: { padding: "15px 15%" },
    [theme.fn.smallerThan("xl")]: { padding: "15px 15%" },
    [theme.fn.smallerThan("lg")]: { padding: "15px 15%" },
    [theme.fn.smallerThan("md")]: { padding: "15px 5%" },
    [theme.fn.smallerThan("sm")]: { padding: "15px 3%" },
    [theme.fn.smallerThan("xs")]: { padding: "15px 2%" },
  },
  hiwActionBtn: {
    backgroundColor: colors.primary,
    height: "50px",
    width: "300px",
    borderRadius: "25px",
  },
  hiwStepsGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  // css for step
  hiwStepWrapper: {
    margin: "15px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 25,
    width: "50%",
    /*
    [theme.fn.largerThan("xl")]: { width: "50%" },
    [theme.fn.smallerThan("xl")]: { width: "50%" },
    [theme.fn.smallerThan("lg")]: { width: "50%" },
    [theme.fn.smallerThan("md")]: { width: "50%" },
      [theme.fn.smallerThan("sm")]: { width: "50%" },
    */
    [theme.fn.smallerThan("xs")]: { width: "100%" },
  },
  hiwstepIconBox: {
    padding: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `5px solid ${colors.primary}`,
    backgroundColor: "#e2e9e8",
    borderRadius: 5,
  },
  hiwStepText: {},
}));

export default hwiStyles;
