import { Card, Group, List, Text, ThemeIcon } from "@mantine/core";
import React from "react";
import { IconCircleCheck, IconCircleDashed } from "@tabler/icons-react";
import { colors } from "../../configs";

const ServiceCard = ({ sList, title, icon }: any) => {
  return (
    <Card withBorder shadow="sm" radius="md" sx={{ alignSelf: "center" }}>
      <Card.Section withBorder mih="20">
        <Group
          position="apart"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 10,
            textAlign: "center",
          }}>
          {icon}

          <Text
            weight={500}
            sx={{
              fontSize: "15px",
              color: colors.primary,
              fontWeight: "bold",
            }}>
            {title}
          </Text>
        </Group>
      </Card.Section>

      {/* 
                    <Text mt="sm" color="dimmed" size="sm">
          <Text component="span" inherit color="blue">
            200+ images uploaded
          </Text>{" "}
          since last visit, review them to select which one should be added to
          your gallery
        </Text>
            */}

      <Card.Section mt="sm" p="sm" sx={{ minHeight: "175px" }}>
        <List
          spacing="xs"
          size="sm"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconCircleCheck size="1rem" color={colors.primary} />
            </ThemeIcon>
          }>
          {sList.map((sText: any) => (
            <List.Item
              icon={
                <ThemeIcon color={colors.primary} size={24} radius="xl">
                  <IconCircleDashed size="1rem" />
                </ThemeIcon>
              }>
              <Text sx={{ color: colors.primary, fontSize: "15px" }}>
                {sText}
              </Text>
            </List.Item>
          ))}
        </List>
      </Card.Section>
    </Card>
  );
};

export default ServiceCard;
